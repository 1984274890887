import {ReactComponent as MagnifyingGlassIcon} from '../../../assets/icons/regular/magnifying-glass.svg';
import {ReactComponent as HouseHeart} from '../../../assets/icons/regular/house-heart.svg';
import {ReactComponent as CompassLight} from '../../../assets/icons/compass.svg';
import {ReactComponent as EnvelopeIcon} from '../../../assets/icons/envelope.svg';
import {ReactComponent as BellIcon} from '../../../assets/icons/bell.svg';
import {ReactComponent as ChartMixedUpCircleDollarIcon} from '../../../assets/icons/chart-mixed-up-circle-dollar.svg';

import {ReactComponent as MagnifyingGlassSolidIcon} from '../../../assets/icons/magnifying-glass-solid.svg';
import {ReactComponent as HouseHeartSolid} from '../../../assets/icons/house-heart-solid.svg';
import {ReactComponent as CompassSolid} from '../../../assets/icons/compass-solid.svg';
import {ReactComponent as EnvelopeSolidIcon} from '../../../assets/icons/envelope-solid.svg';
import {ReactComponent as BellSolidIcon} from '../../../assets/icons/bell-solid.svg';
import {ReactComponent as ChartMixedUpCircleDollarSolidIcon} from '../../../assets/icons/chart-mixed-up-circle-dollar-solid.svg';

import {Nav} from '../../../components/nav-section';

const navConfig: Nav[] = [
    {
        title: 'home_page',
        path: '/',
        icon: HouseHeart,
        activeIcon: HouseHeartSolid
    },
    {
        title: 'search',
        path: '/explore/search',
        icon: MagnifyingGlassIcon,
        activeIcon: MagnifyingGlassSolidIcon
    },
    {
        title: 'explore',
        path: '/explore',
        icon: CompassLight,
        activeIcon: CompassSolid
    },
    // {
    //     title: 'Packs',
    //     path: '/packs/explore',
    //     icon: BoxTappedLight,
    //     activeIcon: BoxTappedSolid
    // },
    {
        title: 'messages',
        path: '/direct/inbox',
        icon: EnvelopeIcon,
        activeIcon: EnvelopeSolidIcon
    },
    {
        title: 'notifications',
        path: '/notifications',
        icon: BellIcon,
        activeIcon: BellSolidIcon
    }
]

export default navConfig;
