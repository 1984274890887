import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MediaType} from "../../models/MediaType";

export interface MediaParams {
    key: string;
    type: MediaType;
    contentType: string;
    file: File;
    status: string;
    progress: number;
    track: any[];
    duration?: number;
    height?: number;
    width?: number;
}

export interface ChatState {
    content: string;
    medias: MediaParams[];
    price?: number;
    emojiMode: boolean;
    status: 'await' | 'sending' | 'error';
    uploadProgress?: number;
}

const initialState: ChatState = {
    content: '',
    medias: [],
    emojiMode: false,
    status: 'await'
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        clearState: state => state = initialState,
        changeStatus: (state, action: PayloadAction<'await' | 'sending' | 'error'>) => {
            state.status = action.payload;

            if (state.status === 'sending') {
                state.emojiMode = false
            }
        },
        changeUploadProgress: (state, action: PayloadAction<number>) => {
            state.uploadProgress = action.payload
        },
        changeContent: (state, action: PayloadAction<string>) => {
            const CHARACTER_LIMIT = 150;
            state.content = action.payload.slice(0, CHARACTER_LIMIT);
        },
        changePrice: (state, action: PayloadAction<number | undefined>) => {
            state.price = action.payload;
        },
        toggleEmojiMode: (state, action: PayloadAction<boolean>) => {
            state.emojiMode = action.payload;
        },
        addEmoji: (state, action: PayloadAction<string>) => {
            const CHARACTER_LIMIT = 150;

            const emoji = action.payload;
            const content = state.content;

            if (!content || content.length < CHARACTER_LIMIT) {
                state.content = content ? `${content}${emoji}` : emoji;
            }
        },
        addMedia: (state, action: PayloadAction<MediaParams>) => {
            state.medias.push(action.payload);
        },
        removeMedia: (state, action: PayloadAction<string>) => {
            state.medias = state.medias.filter(mediaState => mediaState.file.name !== action.payload);
        },
        removeAllMedias: (state) => {
            state.medias = initialState.medias
            state.price = initialState.price
        },
        deleteLastCharacter: state => {
            state.content = state.content.replace(/[^\w\s]$|.$/u, '');
        },
    }
})

export const { clearState, changeStatus, changeUploadProgress, changeContent, changePrice, toggleEmojiMode, deleteLastCharacter, addEmoji, addMedia, removeMedia, removeAllMedias } = chatSlice.actions

export default chatSlice.reducer