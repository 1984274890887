// hooks
import useAuth from '../hooks/useAuth';
// routes
import {ReactNode} from "react";
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

interface GuestGuardProps {
  children: ReactNode
}

export default function NoGuard({ children }: GuestGuardProps) {
  const { isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen isDashboard={false} />;
  }

  return <>{children}</>;
}
