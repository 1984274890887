import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type PaymentMethod = 'pix' | 'card';

export interface PaymentState {
    method?: PaymentMethod;
    country: string
}

const initialState: PaymentState = {
    country: 'BR'
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        clearState: state => state = initialState,
        clearPaymentMethod: state => {
            state.method = initialState.method
        },
        changeMethod: (state, action: PayloadAction<PaymentMethod>) => {
            state.method = action.payload;
        },
        changeCountry: (state, action: PayloadAction<string>) => {
            state.country = action.payload;
        },
    }
})

export const {clearState, clearPaymentMethod, changeMethod, changeCountry} = paymentSlice.actions

export default paymentSlice.reducer