import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    updateProfile,
    User
} from 'firebase/auth';
import {auth} from '../firebase';
import {UserRegisterRequest} from '../models/UserRegisterRequest';
import {userService} from './UserService';
import Bugsnag from "@bugsnag/js";
import {enqueueSnackbar} from "notistack";

class AuthService {
    signInWithEmailAndPassword = async (email: string, password: string) =>
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;

                if (user) {
                    await this.completeLogin(user);
                }
            })
            .catch((error) => this._processErrorOnSignIn(error));

    createUserWithEmailAndPassword = async (
        displayName: string,
        email: string,
        password: string
    ) =>
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;

                if (user) {
                    await updateProfile(user, {displayName: displayName});
                    await this.completeLogin(user);
                }
            });

    sendPasswordResetEmail = (email: string) => {
        return sendPasswordResetEmail(auth, email).then(() => {
            // Password reset email sent.
        });
    };

    private signOut = () =>
        auth.signOut()
            .then(() => {
                console.log('Sign-out successful.');
            })
            .catch((error) => {
                console.log(`An error happened. ${error}`);
            });

    completeLogin = async (user: User) => {
        try {
            const loginResponse = await userService.completeLogin(user.uid)
            if (!loginResponse.uid) {
                await this._createAccount(user);
                auth.signOut().then(() => auth.updateCurrentUser(user))
            }
        } catch (error: unknown) {
            await this.signOut()
            throw error
        }
    }

    async _createAccount(user: any) {
        const registerRequest = {} as UserRegisterRequest;
        registerRequest.id = user.uid;
        registerRequest.name = user.displayName || 'User';
        registerRequest.email = user.email || undefined;
        await userService
            .register(registerRequest)
            .catch(async (error) => {
                console.log(error);
            });
    }

    _processErrorOnSignIn(error) {
        Bugsnag.notify(error);
        this.signOut();
        console.log(`An error happened. ${error}`);

        if (error.code) {
            switch (error.code) {
                case 'auth/email-already-in-use':
                    throw new Error('Já existe uma conta para esse e-mail.');
                case 'auth/wrong-password':
                    throw new Error('Desculpe, sua senha está incorreta.');
                case 'auth/popup-closed-by-user':
                    break;
                default:
                    throw new Error('Ocorreu um erro. ' + error.code);
                // The account already exists for that email.
            }
        } else {
            throw error;
        }
    }
}

export const authService = new AuthService();
