import {useContext} from 'react';
import {LoginContext} from "../context/LoginContext";

const useLogin = () => {
    const context = useContext(LoginContext);

    if (!context) throw new Error('Auth context must be use inside AuthProvider');

    return context;
};

export default useLogin;
