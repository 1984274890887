import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from "react";
import {Trans, useTranslation} from "react-i18next";

import {ReactComponent as UpIcon} from '../../assets/icons/up.svg';

type SignButtonType = 'sign_up' | 'sign_in';
type SignProvider = 'Google' | 'Twitter';

interface SimpleDialogProps extends DialogProps {
    type: SignButtonType;
    provider: SignProvider;
    onCancel: () => void;
}

const InstagramWebViewWarnModal: React.FC<SimpleDialogProps> = ({type, provider, onCancel, ...props}) => {
    const {t} = useTranslation();

    return (
        <Dialog
            {...props}
            scroll={"paper"}
            fullWidth={true}
            maxWidth={'xs'}
            sx={{'& .MuiPaper-root': {position: 'absolute', top: 0, right: 0, m: 1}}}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogContent>
                <UpIcon className="text-gray-900 h-5 absolute top-3 right-3 animate-bounce"/>

                <div className="flex flex-col gap-y-4 text-gray-900">
                    <div className="font-semibold text-red-500">
                        {t('you_are_on_instagram_browser')}
                    </div>

                    {type === 'sign_up' ? (
                        <>
                            <div className="">
                                {t('to_sign_up_with_provider', {provider: provider})}
                                <ul className="pl-4 mt-1 list-[square]">
                                    <li>{t('click_on_the_dots_above')}</li>
                                    <li><Trans t={t} i18nKey="then_click_on_open_in_system_browser"></Trans></li>
                                </ul>
                            </div>

                            <div>
                                {t('or_create_an_account_with_email_and_password')}
                            </div>
                        </>
                    ) : type === 'sign_in' ? (
                        <>
                            <div className="">
                                {t('to_sign_in_with_provider', {provider: provider})}
                                <ul className="pl-4 mt-1 list-[square]">
                                    <li>{t('click_on_the_dots_above')}</li>
                                    <li><Trans t={t} i18nKey="then_click_on_open_in_system_browser"></Trans></li>
                                </ul>
                            </div>

                            <div>
                                {t('or_sign_in_with_email')}
                            </div>
                        </>
                    ) : null}

                </div>
            </DialogContent>
        </Dialog>
    );
}

export default InstagramWebViewWarnModal;
