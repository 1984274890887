import {List, ListItem, Popover} from "konsta/react";
import React from "react";

type Props = {
    target: React.Ref<HTMLElement> | HTMLElement | string
    children: React.ReactElement
    onClose: () => void
}

export const PopoverOpener = ({ children, onClose, ...props }: Props) => {
    return (
        <Popover
            {...props}
            onBackdropClick={onClose}
            className="cursor-pointer">
            {children}
        </Popover>
    );
};