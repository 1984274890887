import CircularProgress, {circularProgressClasses,} from '@mui/material/CircularProgress';

const LoadingIndicator = () =>
(
    <div className="flex items-center justify-center h-full w-full">
        <svg className="h-10 w-10 absolute">
            <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                <stop offset="20%" stopColor="#D8097E" />
                <stop offset="90%" stopColor="#74C8E1" />
            </linearGradient>
        </svg>

        <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
                // animationDuration: '550ms',
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                    stroke: 'url(#linearColors)'
                },
            }}
            size={40}
            thickness={2}
        />
    </div>
);

export default LoadingIndicator;
