import NavList from './NavList';
import navConfig from './NavConfig';

import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import LogotypeHorizontalIcon from '../../../components/shared/icons/LogotypeHorizontalIcon';
import { Link } from 'react-router-dom';
import * as React from 'react';
import NavbarProfileMenu from './NavbarProfileMenu';
import useAuth from '../../../hooks/useAuth';

interface Props {
    collapsed: boolean;
}

const NavbarVertical = ({collapsed}: Props) => {
    const {isAuthenticated} = useAuth();

    return (
        <div className="h-full lg:flex lg:flex-shrink-0">
            <div className="flex h-full w-full flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex min-h-0 flex-1 flex-col hairline-r">
                    <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                        <div className="flex flex-shrink-0 items-start px-4">
                            <Link className="" to="/">
                                {collapsed ? (
                                    <Logo className="h-12 w-full"/>
                                ) : (
                                    <LogotypeHorizontalIcon className="h-8"/>
                                )}
                            </Link>
                        </div>
                        <nav className="mt-8 flex-1" aria-label="Sidebar">
                            <NavList collapsed={collapsed} navConfig={navConfig} />
                        </nav>
                    </div>
                    <div className="flex flex-shrink-0">
                        {isAuthenticated ? <NavbarProfileMenu collapsed={collapsed} /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavbarVertical;
