import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import 'react-phone-number-input/style.css';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import CreatorProfileContext from '../../context/CreatorProfileConfigContext';
import banks from 'bancos-brasileiros';

import {useMutation, useQueryClient} from 'react-query';
import {UserBank} from '../../models/UserBank';
import {useLocation, useNavigate} from 'react-router-dom';
import {userService} from '../../services/UserService';
import InputMask from "react-input-mask";

import {cpf} from 'cpf-cnpj-validator';
import Autocomplete from "@mui/material/Autocomplete";
import {CreatorAccount} from "../../models/CreatorAccount";
import {useSnackbar} from "notistack";
import useBackRoute from "../../hooks/useBackRoute";
import Bugsnag from "@bugsnag/js";
import {List, ListInput, Navbar, NavbarBackLink} from "konsta/react";
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";
import {CREATOR_TOTAL_STEPS} from "../../pages/CreatorProfileConvertPage";
import PaginatorDot from "../shared/PaginatorDot";

const accountTypes = [
    {value: 'CC', label: 'Conta corrente'},
    {value: 'CP', label: 'Conta poupança'},
    {value: 'CPAG', label: 'Conta de pagamentos'}
]

type FormData = {
    ni: string
    bankCode: string | any;
    agency: string;
    accountNumber: string;
    accountType: string | object;
    pixKey: string;
}

type CreatorAccountBankDataProps = {
    dialogMode?: boolean
}

const CreatorAccountBankData = ({dialogMode}: CreatorAccountBankDataProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {back} = useBackRoute();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const {state, setState} = useContext(CreatorProfileContext);
    const [saving, setSaving] = useState(false);

    const {control, handleSubmit, register, formState, setValue} =
        useForm<FormData>({
            mode: 'all', reValidateMode: 'onChange', defaultValues: {
                ni: state.profile.identity?.ni || '',
                bankCode: state.profile.bank?.bankCode || '',
                agency: state.profile.bank?.agency || '',
                accountNumber: state.profile.bank?.accountNumber || '',
                accountType: state.profile.bank?.accountType || 'CC'
            }
        });
    const {errors, isValid} = formState;
    
    const handleFormSubmit = (event) => {
        event.preventDefault()
        handleSubmit(onSubmit)(event)
    }

    const mutation = useMutation((bank: UserBank) => userService.saveCreatorAccountBank(bank))

    const goToPrev = () => setState({...state, step: 'monetization'});

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        if (saving) return;
        try {
            setSaving(true);

            const bankRequest = {} as UserBank;
            bankRequest.ni = formData.ni;
            bankRequest.bankCode = typeof formData.bankCode === 'string' ? formData.bankCode : formData.bankCode['COMPE'];
            bankRequest.agency = formData.agency;
            bankRequest.accountNumber = formData.accountNumber;
            bankRequest.accountType = typeof formData.accountType === 'string' ? formData.accountType : formData.accountType['value'];

            mutation.mutate(bankRequest, {
                onSuccess: (data, variables, context) => {
                    queryClient.setQueryData('creator_profile', (oldData: CreatorAccount | undefined) => {
                        return {
                            ...oldData || {} as CreatorAccount,
                            bank: bankRequest,
                        } as CreatorAccount
                    })

                    if (dialogMode) {
                        enqueueSnackbar(t('data_saved_successfully'), { variant: 'default' });
                        back();
                    } else {
                        navigate('/settings/identity_confirm/', {state: location.state, replace: true});
                    }
                    setSaving(false);
                }
            });
        } catch (error: any) {
            setSaving(false);
            Bugsnag.notify(error);
            enqueueSnackbar(t(`Error at saving user bank data: ${error.message}`), { variant: 'error' });
            console.log(`Error at saving user bank data: ${error.message}`);
        }
    };

    return (
        <>
            <Navbar
                left={
                    <NavbarBackLink showText={false} onClick={goToPrev} className="theme-on-surface ml-2"/>
                }
                right={
                    <KonstaLoadingButton clear
                                         loading={saving}
                                         disabled={!isValid}
                                         onClick={handleFormSubmit}>
                        {dialogMode ? t('save') : t('done')}
                    </KonstaLoadingButton>
                }
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-center">
                    {!dialogMode && (
                        <PaginatorDot className="mb-4" curPage={4} maxPage={CREATOR_TOTAL_STEPS}/>)}
                    <p className="text-3xl leading-8 font-medium tracking-tight">
                        {t('Dados bancários para recebimento dos ganhos')}
                    </p>
                    <p className="mt-2 sm:px-4 max-w-2xl text-lg font-light">
                        {t(
                            'Esses dados serão solicitados pelo banco para realizar as transferências.'
                        )}
                    </p>
                </div>

                <List>
                    <Controller
                        control={control}
                        {...register('ni', {
                            required: true,
                            validate: {
                                valid: (value) =>
                                    cpf.isValid(value),
                            },
                        })}
                        name="ni"
                        render={({field}) => (
                            <>
                                <ListInput
                                    outline
                                    label="CPF"
                                    type="text"
                                    info={t('O CPF será utilizado para realizar as transferências.')}
                                    error={
                                        !!errors.ni && errors.ni && errors.ni.type === 'valid' && t('invalid_cpf')
                                    }
                                    input={
                                        <InputMask
                                            id="ni-input"
                                            {...field}
                                            mask="999.999.999-99"
                                            maskPlaceholder={null}
                                            type="text"
                                            className="block text-base appearance-none w-full focus:outline-none bg-transparent h-10 placeholder-black placeholder-opacity-30"
                                        />
                                    }
                                />
                            </>
                        )}
                    />

                    <Controller
                        {...register('bankCode', {
                            required: {
                                value: true,
                                message: ''
                            },
                        })}
                        control={control}
                        name="bankCode"
                        render={({field: {value, name, onChange}}) => (
                            <ListInput
                                outline
                                name={name}
                                label={t('bank')}
                                type="select"
                                dropdown
                                input={
                                    <Autocomplete
                                        onChange={(e, data) => onChange(data)}
                                        value={value}
                                        options={banks}
                                        autoHighlight
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <input type="text"
                                                       {...params.inputProps}
                                                       className="block text-base appearance-none w-full focus:outline-none bg-transparent h-10 placeholder-black placeholder-opacity-30"/>
                                            </div>
                                        )}
                                        getOptionLabel={(option: any) => {
                                            if (!option) {
                                                return ''
                                            }

                                            if (typeof option === 'string') {
                                                return banks.filter(option => option.COMPE === state.profile.bank?.bankCode)?.map(banco => `${banco.COMPE} - ${banco.ShortName}`)[0];
                                            } else {
                                                return !!option && !!option.COMPE ? `${option.COMPE} - ${option.ShortName}` : '';
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value1) => {
                                            if (typeof value1 === 'string') {
                                                return option.COMPE === value1;
                                            } else {
                                                return option.COMPE === value1.COMPE;
                                            }
                                        }}
                                    />
                                }
                                error={
                                    errors.bankCode && errors.bankCode.type === 'required' ? (
                                        t('required_field')
                                    ) : null
                                }
                            >
                            </ListInput>
                        )}
                    />

                    <Controller
                        control={control}
                        {...register('accountType', {
                            required: {
                                value: true,
                                message: ''
                            }
                        })}
                        render={({
                                     field: {onChange, ...restField},
                                 }) => (
                            <ListInput
                                {...restField}
                                outline
                                label={t('account_type')}
                                type="select"
                                dropdown
                                error={
                                    !!errors.accountType && errors.accountType && errors.accountType.type === 'required' && t('required_field')
                                }
                                onChange={e => setValue('accountType', e.target.value, {shouldValidate: true})}
                            >
                                <option disabled hidden value="" />
                                {accountTypes.map((accountType) => (
                                    <option key={accountType.value}
                                            value={accountType.value}>{accountType.label}</option>
                                ))}
                            </ListInput>
                        )}
                    />

                    <Controller
                        {...register('agency', {
                            required: {
                                value: true,
                                message: ''
                            },
                            maxLength: 4,
                            pattern: {
                                value: /^\d*$/,
                                message: t('use_only_numbers'),
                            },
                        })}
                        control={control}
                        name="agency"
                        render={({field}) => (
                            <ListInput
                                outline
                                label={t('agency')}
                                type="text"
                                input={<input {...field}
                                              className="block text-base appearance-none w-full focus:outline-none bg-transparent h-10 placeholder-black placeholder-opacity-30"/>}
                                error={
                                    errors.agency && errors.agency.type === 'pattern' ? (
                                        errors.agency.message
                                    ) : errors.agency && errors.agency.type === 'required' ? (
                                        t('required_field')
                                    ) : null
                                }
                            />
                        )}
                    />

                    <Controller
                        {...register('accountNumber', {
                            required: {
                                value: true,
                                message: t('required_field')
                            },
                            maxLength: 20,
                            pattern: {
                                value: /^\d*$/,
                                message: t('use_only_numbers'),
                            },
                        })}
                        control={control}
                        name="accountNumber"
                        render={({field}) => (
                            <ListInput
                                outline
                                label={t('account_number_with_digit')}
                                type="text"
                                input={<input {...field}
                                              className="block text-base appearance-none w-full focus:outline-none bg-transparent h-10 placeholder-black placeholder-opacity-30"/>}
                                error={
                                    errors.accountNumber && errors.accountNumber.type === 'pattern' ? (
                                        errors.accountNumber.message
                                    ) : errors.accountNumber && errors.accountNumber.type === 'required' ? (
                                        t('required_field')
                                    ) : null
                                }
                            />
                        )}
                    />
                </List>
            </form>
        </>
    );
};

export default CreatorAccountBankData;
