import React, {memo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import useResponsive from '../../../hooks/useResponsive';
import CirclePlusLightIcon from '../icons/CirclePlusLightIcon';
import {ReactComponent as ClockTwoThirtyIcon} from '../../../assets/icons/clock-two-thirty.svg';
import {ReactComponent as BoxIcon} from '../../../assets/icons/box.svg';
import {ReactComponent as PhotoFilmIcon} from '../../../assets/icons/photo-film.svg';
import {List, ListItem} from "konsta/react";
import {useModal} from "../../../shared/modal-provider";
import {PopoverOpener} from "../../PopoverOpener";

const actions = [
    {
        icon: <BoxIcon className="theme-on-surface w-5"/>,
        name: 'Pack',
        target: '/pack/create/',
        modalMode: false
    },
    {
        icon: <PhotoFilmIcon className="theme-on-surface w-5"/>,
        name: 'post',
        target: '/post/create/',
        modalMode: true
    },
    {
        icon: <ClockTwoThirtyIcon className="theme-on-surface w-5"/>,
        name: 'story_for_subscribers',
        target: '/stories/create/',
        modalMode: true
    }
]

interface Props {
    collapsed: boolean;
}

const CreateContentButton = ({collapsed}: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useResponsive('down', 'sm')
    const {showModal, hideModal} = useModal()

    const openPopover = (targetRef) => {
        const modal = showModal(PopoverOpener, {
            target: targetRef,
            children: (
                <List nested>
                    {actions.map((action) => (
                        <ListItem
                            key={action.name}
                            title={t(action.name)}
                            media={action.icon}
                            onClick={() => {
                                hideModal(modal.id)

                                if (isMobile) {
                                    navigate(action.target)
                                } else {
                                    navigate(action.target, {state: {backgroundLocation: location}})
                                }
                            }}
                        />
                    ))}
                </List>
            ),
            onClose: () => {
                hideModal(modal.id)
            }
        })
    }

    return (
        <React.Fragment>
            {collapsed ? (
                <ListItem
                    id="create-content-popover"
                    onClick={() => openPopover(`#create-content-popover`)}
                    dividers={false}
                    media={<CirclePlusLightIcon className="theme-on-surface h-6 w-6"/>}
                />
            ) : (
                <div className="mt-2 px-4 py-2" id="create-content-popover">
                    <Button variant="contained" size="large"
                            className="bg-primary-500 font-bold text-xl normal-case rounded-full w-[210px]"
                            onClick={() => openPopover(`#create-content-popover`)}
                            disableElevation>
                        {t('create')}
                    </Button>
                </div>
            )}
        </React.Fragment>
    )
}

export default memo(CreateContentButton)
