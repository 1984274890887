import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import feedReducer from '../components/feed/feedSlice';
import commentReducer from './slices/commentSlice';
import chatReducer from './slices/chatSlice';
import chatFilterReducer from './slices/chatFilterSlice';
import tipReducer from './slices/tipSlice';
import identityVerificationReducer from "./slices/identityVerificationSlice";
import paymentReducer from "./slices/paymentSlice";
import storiesReducer from "./slices/storiesSlice";
import createStoryReducer from "./slices/createStorySlice";

export const store = configureStore({
    reducer: {
        feed: feedReducer,
        comment: commentReducer,
        chat: chatReducer,
        chatFilter: chatFilterReducer,
        tip: tipReducer,
        identityVerification: identityVerificationReducer,
        payment: paymentReducer,
        stories: storiesReducer,
        createStory: createStoryReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
