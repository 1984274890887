import {useLocation, useNavigate} from 'react-router-dom';
import {getActive, Nav} from './index';
import {useTranslation} from 'react-i18next';
import {useQuery} from "react-query";
import useAuth from "../../hooks/useAuth";
import Badge from "@mui/material/Badge";
import {notificationService} from "../../services/NotificationService";
import NotificationsSummaryBubble from "../shared/components/NotificationsSummaryBubble";
import React from "react";
import {ListItem} from "konsta/react";

interface Props {
    collapsed: boolean;
    item: Nav;
}

const NavNotificationsItem = ({collapsed, item}: Props) => {
    const {title, path} = item;
    const {user} = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const active = getActive(item.path, pathname);

    const {data} = useQuery(
        ['notifications_summary', user?.id], () => notificationService.getSummary(),
        {
            enabled: !!user,
            refetchOnWindowFocus: false
        }
    )

    return (
        <ListItem dividers={false}
                  title={!collapsed && t(title)}
                  media={
                      <NotificationsSummaryBubble summary={data} placement="right">
                          <Badge variant="dot" invisible={!data} color="error">
                              {active ? <item.activeIcon className="theme-on-surface h-6 w-6"/> :
                                  <item.icon className="theme-on-surface h-6 w-6"/>}
                          </Badge>
                      </NotificationsSummaryBubble>
                  }
                  onClick={() => navigate(path)}
        />
    )
}

export default NavNotificationsItem;
