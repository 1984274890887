import React from "react";
import {useTranslation} from "react-i18next";

import {Dialog, DialogButton} from 'konsta/react';

interface Props {
    onConfirm: () => void;
    onCancel: () => void;
}

const SuspendedAccountModal: React.FC<Props> = ({
                                                    onConfirm,
                                                    onCancel,
                                                    ...props
                                                }) => {
    const {t} = useTranslation()

    return (
        <Dialog
            {...props}
            onBackdropClick={onCancel}
            className="theme-on-surface"
            title="Erro"
            content="Sua conta foi desativada por violar nossos termos. Saiba como você pode restaurar sua conta."
            buttons={
                <>
                    <DialogButton className="font-bold" onClick={() => onCancel()}>
                        OK
                    </DialogButton>
                    <DialogButton onClick={() => onConfirm()}>
                        {t('learn_more')}
                    </DialogButton>
                </>
            }
        />
    );
}

export default SuspendedAccountModal;