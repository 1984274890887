// @mui
import { alpha, styled } from '@mui/material/styles';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';
import LogotypeVerticalIcon from "./shared/icons/LogotypeVerticalIcon";
import React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      {/*<ProgressBar />*/}

      {!isDashboard && (
        <RootStyle {...other} className="theme-surface">

          <LogotypeVerticalIcon className="w-36 sm:w-52"/>

        </RootStyle>
      )}
    </>
  );
}
