import {useTranslation} from "react-i18next";
import {Dialog, DialogButton} from "konsta/react";
import React from "react"

interface Props {
    onConfirm: () => void
    onCancel: () => void
}

const DeletedAccountModal = ({onConfirm, onCancel, ...props}: Props) => {

    const {t} = useTranslation()

    return (
        <Dialog
            {...props}
            onBackdropClick={onCancel}
            className="theme-on-surface"
            title="Erro"
            content="Você excluiu sua conta permanentemente. Caso deseje você pode criar outra conta."
            buttons={
                <>
                    <DialogButton onClick={() => onConfirm()}>
                        {t('create_account')}
                    </DialogButton>
                    <DialogButton className="font-bold" onClick={() => onCancel()}>
                        OK
                    </DialogButton>
                </>
            }
        />
    )
}

export default DeletedAccountModal