import {DialogProps} from "@mui/material/Dialog";
import React from "react";
import {Dialog, DialogButton} from "konsta/react";
import {useTranslation} from "react-i18next";

type ConfirmDialogProps = {
    title: string | React.ReactElement
    contentText?: string | React.ReactElement
    confirmText: string
    onConfirm: () => void
    onCancel: () => void
}

export const ConfirmDialog = ({
                                  title,
                                  contentText,
                                  confirmText,
                                  onConfirm,
                                  onCancel,
                                  ...props
                              }: ConfirmDialogProps) => {

    const {t} = useTranslation()

    return (
        <Dialog
            {...props}
            onBackdropClick={onCancel}
            title={title}
            content={contentText}
            className="theme-on-surface"
            buttons={
                <>
                    <DialogButton onClick={onCancel}>
                        {t('cancel')}
                    </DialogButton>
                    <DialogButton strong onClick={onConfirm}>
                        {confirmText}
                    </DialogButton>
                </>
            }
        />
    )
}