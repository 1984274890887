import api from '../apis';
import {UserRegisterRequest} from '../models/UserRegisterRequest';
import {UserSummary} from '../models/UserSummary';
import {PhotoRequest} from '../types/PhotoRequest';
import {User} from '../types/User';
import {UserUpdateRequest} from '../types/UserUpdateRequest';
import {getImageUrl} from '../utils/ImageUtils';
import {ResponseData} from '../types/ResponseData';
import {CreatorAccount} from '../models/CreatorAccount';
import {UserBank} from '../models/UserBank';
import {CreatorProfileSummary} from '../models/CreatorProfileSummary';
import {Identity} from '../models/Identity';
import {IdentityStatus} from '../types/IdentityStatus';
import {Category} from '../models/Category';
import {PaidMembershipRequest} from '../models/PaidMembershipRequest';
import {PaidMembership} from "../models/PaidMembership";
import {Referral} from "../models/Referral";
import {ProfileViewsActivitiesDTO} from "../models/ProfileViewsActivitiesDTO";
import {InterestsRequest} from "../models/InterestsRequest";
import {UserCategoriesRequest} from "../requests/UserCategoriesRequest";
import {UserContentPolicyRequest} from "../requests/UserContentPolicyRequest";
import {UserBasicIdentityRequest} from "../requests/UserBasicIdentityRequest";
import {DeactivateAccountRequestDTO} from "../models/DeactivateAccountRequestDTO";
import {DeleteAccountRequestDTO} from "../models/DeleteAccountRequestDTO";
import {ResetPasswordRequestDTO} from "../models/ResetPasswordRequestDTO";

class UserService {
    register = async (request: UserRegisterRequest) => api
        .post('auth/users', request)
        .then((response) => response.data)
        .catch((error) => this.handleError(error));

    updateUser = (request: UserUpdateRequest) =>
        api
            .put('auth/users', request)
            .then((response) => response.data);

    getPaidMembershipDetails = (userId: string): Promise<PaidMembership> =>
        api
            .get(`auth/users/${userId}/paid-membership`)
            .then((response) => response.data);

    getUserSummaryV2 = (userId: string): Promise<UserSummary> =>
        api
            .get(`auth/users/summary/v2/${userId}`)
            .then((response) => response.data);

    getUser = (userId: string): Promise<User> =>
        api
            .get(`auth/users/${userId}`)
            .then((response) => {
                const data = response.data;
                if (data && data.profile.photoBucket && data.profile.photoKey) {
                    data.profile.photoUrl = getImageUrl(data.profile.photoBucket, data.profile.photoKey, 256, 256) || '';
                }
                return data;
            });

    getUserById = (userId: string): Promise<User> =>
        api
            .get(`auth/users/id/${userId}`)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    async getMe(): Promise<UserSummary> {
        return api
            .get('auth/users/me')
            .then((response) => response.data);
    }

    getUserSummary = (userId: string): Promise<UserSummary> => {
        return api
            .get(`auth/users/summary/${userId}`)
            .then((response) => response.data);
    };

    getSuggestions(pageParam = 0, size = 5): Promise<ResponseData<UserSummary>> {
        return api
            .get(`auth/users/suggestions?page=${pageParam}&size=${size}`)
            .then((response) => response.data);
    }

    getHighProfileSuggestions = (): Promise<UserSummary[]> =>
        api
            .get('auth/users/high-profile-suggestions')
            .then((response) => response.data);

    getFollowingAccounts(userId: string, pageParam = 0, size = 5): Promise<ResponseData<UserSummary>> {
        return api
            .get(`auth/users/${userId}/following?page=${pageParam}&size=${size}`)
            .then((response) => response.data);
    }

    search(query: string, pageParam = 0, size = 16): Promise<ResponseData<UserSummary>> {
        if (!query) {
            return new Promise<ResponseData<UserSummary>>(resolve => resolve({} as ResponseData));
        }
        return api
            .get(`auth/users/search?query=${query}&page=${pageParam}&size=${size}`)
            .then((response) => response.data);
    };

    verifyUsername = (username: string): Promise<boolean> =>
        api
            .get(`auth/users/username/available/${username}`)
            .then((response) => response.data);

    completeLogin = (uid: string): Promise<any> =>
        api
            .get(`auth/users/${uid}/complete-login`)
            .then((response) => response.data)

    updatePhoto = (request: PhotoRequest): Promise<{photoPicURL: string}> =>
        api
            .post('auth/users/me/photo', request)
            .then((response) => response.data);

    updateCoverPhoto = (request: PhotoRequest): Promise<{photoPicURL: string}> =>
        api
            .post('auth/users/me/cover-photo', request)
            .then((response) => response.data);

    saveCreatorAccount = (request: CreatorAccount): Promise<void> =>
        api
            .post('auth/users/creator-account', request)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    saveCreatorAccountBank = (request: UserBank): Promise<void> =>
        api
            .post('auth/users/creator-account/bank', request)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    saveUserCategories = (request: UserCategoriesRequest): Promise<void> =>
        api
            .post('auth/users/categories', request)
            .then((response) => response.data);

    saveUserContentPolicy = (request: UserContentPolicyRequest): Promise<void> =>
        api
            .post('auth/users/content-policy', request)
            .then((response) => response.data);

    savePaidMembership = (request: PaidMembershipRequest): Promise<void> =>
        api
            .put('auth/users/profile/paid-membership', request)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    getCategories = (): Promise<Category[]> =>
        api
            .get(`auth/users/categories`)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    getCreatorAccount = (): Promise<CreatorAccount> =>
        api
            .get(`auth/users/creator-account`)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    saveCreatorBankData = (request: UserBank): Promise<void> =>
        api
            .post('auth/users/creator-bank', request)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    getCreatorBankData = (): Promise<CreatorAccount> =>
        api
            .get(`auth/users/creator-bank`)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    getCreatorAccountSummary = (userId: string): Promise<CreatorProfileSummary> =>
        api
            .get(`auth/users/${userId}/creator-account/summary`)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    saveIdentity = (request: Identity): Promise<void> =>
        api
            .post('auth/users/identity', request)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    saveBasicIdentity = (request: UserBasicIdentityRequest): Promise<void> =>
        api
            .post('auth/users/basic-identity', request)
            .then((response) => response.data);

    getIdentityStatus = (): Promise<IdentityStatus> =>
        api
            .get(`auth/users/identity/status`)
            .then((response) => response.data)
            .catch((error) => this.handleError(error));

    saveProfileViewActivity(userUid: string, userAgent: string, from?: string): Promise<void> {
        const payload = {  userAgent, from }

        return api
            .post(`auth/users/profile/${userUid}/viewed`, payload)
    };

    getReferrals = (pageParam = 0, size = 16): Promise<ResponseData<Referral>> =>
        api
            .get(`auth/referrals?page=${pageParam}&size=${size}`)
            .then((response) => response.data);

    getViewStatistics = (): Promise<ProfileViewsActivitiesDTO> =>
        api
            .get(`auth/accounts/views/statistics`)
            .then((response) => response.data);

    saveInterests = (request: InterestsRequest): Promise<void> =>
        api
            .post('auth/users/interests', request)
            .then((response) => response.data)

    deactivate = (request: DeactivateAccountRequestDTO): Promise<void> =>
        api
            .post('auth/users/deactivate', request)
            .then((response) => response.data)

    delete = (request: DeleteAccountRequestDTO): Promise<void> =>
        api
            .post('auth/users/delete', request)
            .then((response) => response.data)

    resetPassword = (request: ResetPasswordRequestDTO): Promise<void> =>
        api
            .post('auth/users/reset-password', request)
            .then((response) => response.data)

    addOrUpdatePreference = (userId: string, preferenceKey: string, preferenceValue: string): Promise<void> =>
        api
            .post(`auth/users/${userId}/preferences?preferenceKey=${preferenceKey}&preferenceValue=${preferenceValue}`)
            .then((response) => response.data)

    handleError(error: any) {
        throw new Error(error.message);
    }
}

export const userService = new UserService();
