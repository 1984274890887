import React, { createContext, FunctionComponent, ReactNode, useState } from 'react';
import { CreatorAccount } from '../models/CreatorAccount';

type CreatorAccountStateType = {
    step: 'main' | 'welcome-faq' | 'categories' | 'adult-content' | 'personal-information' | 'bank-data' | 'monetization';
    profile: CreatorAccount;
    loading: boolean;
};

type PropsCreatorProfileContext = {
    state: CreatorAccountStateType;
    setState: React.Dispatch<React.SetStateAction<CreatorAccountStateType>>;
    clearState: () => void;
};

const DEFAULT_VALUE = {
    state: {
        step: 'main',
        profile: {} as CreatorAccount,
        loading: false,
    } as CreatorAccountStateType,
    setState: () => {
    },
    clearState: () => {
    },
};

const CreatorProfileContext =
    createContext<PropsCreatorProfileContext>(DEFAULT_VALUE);

interface CreatorProfileContextProviderProps {
    children?: ReactNode;
}

const CreatorProfileContextProvider: FunctionComponent<CreatorProfileContextProviderProps> = ({children}) => {
    const [state, setState] = useState(DEFAULT_VALUE.state);

    const clearState = () => setState(DEFAULT_VALUE.state);

    return (
        <CreatorProfileContext.Provider
            value={{state, setState, clearState}}
        >
            {children}
        </CreatorProfileContext.Provider>
    );
};

export { CreatorProfileContextProvider };
export default CreatorProfileContext;
