import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, Messaging } from 'firebase/messaging'
import { getAnalytics, isSupported as isAnalyticsSupported, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { UserSummary } from './models/UserSummary';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

type NotificationTokenConfig = {
    onTokenFound?: (token: string) => void;
    onTokenNotFound?: () => void;
}

let requestForToken;

isSupported()
    .then((supported) => {
        if (supported) {
            const messaging: Messaging = getMessaging(app);

            requestForToken = (config?: NotificationTokenConfig): void => {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistration()
                        .then(async (reg) => {
                            getToken(messaging, {
                                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                                serviceWorkerRegistration: reg
                            })
                                .then((currentToken) => {
                                    if (currentToken) {
                                        console.log('current token for client: ', currentToken);

                                        if (config && config.onTokenFound) {
                                            config.onTokenFound(currentToken);
                                        }
                                    } else {
                                        // Show permission request UI
                                        console.log('No registration token available. Request permission to generate one.');
                                        if (config && config.onTokenNotFound) {
                                            config.onTokenNotFound();
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.log('An error occurred while retrieving token. ', err);
                                });
                        })
                }
            };
        }
    });

isAnalyticsSupported()
    .then((supported) => {
        if (supported) {
            const analytics = getAnalytics(app);
            setAnalyticsCollectionEnabled(analytics, true);
        }
    })

const configFirebaseUser = (userSummary: UserSummary): void => {
    // setUserId(analytics, userSummary.id)
    // setUserProperties(analytics, { user_type: userSummary.contentCreator ? 'Content Creator' : 'User' });
};

export {auth, requestForToken, configFirebaseUser};
