import {useNumberFormatter} from "@react-aria/i18n";
import React, {memo} from "react";

type Props = {
    value: number;
    currency: string;
}

const Currency = ({value, currency, className}: Props & React.ComponentProps<'div'>) => {
    const formatter = useNumberFormatter({
        style: 'currency',
        currency,
        minimumFractionDigits: 2
    });

    return <div className={className}>{formatter.format(value)}</div>;
}

export default memo(Currency);