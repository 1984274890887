import React, {useContext} from 'react';
import CreatorAccountPersonalData from '../components/professional-account-config/CreatorAccountPersonalData';
import CreatorProfileAdultContent from '../components/professional-account-config/CreatorProfileAdultContent';
import CreatorProfileContext, {CreatorProfileContextProvider} from '../context/CreatorProfileConfigContext';
import CreatorProfileCategories from '../components/professional-account-config/CreatorProfileCategories';
import CreatorAccountWelcome from '../components/creator-account-settings/CreatorAccountWelcome';
import PaidMembershipForm from "../components/settings/monetization/PaidMembershipForm";
import CreatorAccountBankData from "../components/professional-account-config/CreatorAccountBankData";
import CreatorAccountWelcomeFaq from "../components/creator-account-settings/CreatorAccountWelcomeFaq";

const CreatorProfileConvertPage = () => {
    return (
        <CreatorProfileContextProvider>
            <Content/>
        </CreatorProfileContextProvider>
    );
};

export const CREATOR_TOTAL_STEPS = 5;

const Content = () => {
    const {state} = useContext(CreatorProfileContext);

    return (
        <>
            {state.step === 'main' && <CreatorAccountWelcome/>}

            {state.step === 'welcome-faq' && <CreatorAccountWelcomeFaq/>}

            {state.step === 'categories' && <CreatorProfileCategories/>}

            {state.step === 'adult-content' && <CreatorProfileAdultContent/>}

            {state.step === 'monetization' && <PaidMembershipForm/>}

            {state.step === 'bank-data' && <CreatorAccountBankData/>}

            {state.step === 'personal-information' && <CreatorAccountPersonalData/>}
        </>
    );
};

export default CreatorProfileConvertPage;
