import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type ThreadsFilterType = 'all_messages' | 'unread';

export interface ChatState {
    filterBy: ThreadsFilterType;
}

const initialState: ChatState = {
    filterBy: 'all_messages',
}

export const chatFilterSlice = createSlice({
    name: 'chat/filter',
    initialState,
    reducers: {
        clearFilter: state => state = initialState,
        changeFilterBy: (state, action: PayloadAction<ThreadsFilterType>) => {
            state.filterBy = action.payload;
        }
    }
})

export const {clearFilter, changeFilterBy} = chatFilterSlice.actions

export default chatFilterSlice.reducer