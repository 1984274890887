import useResponsive from '../../../hooks/useResponsive';
import SignupOptions from "./SignupOptions";
import React, {useLayoutEffect} from "react";
import SignupEmail from "./SignupEmail";
import {SignupProvider} from "../../../context/SignupContext";
import useSignup from "../../../hooks/useSignup";
import useBackRoute from "../../../hooks/useBackRoute";
import {useCookies} from "react-cookie";
import {useLocation} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import {Navbar, Page, Popup} from "konsta/react";
import Iconify from "../../iconify";
import IconButton from "@mui/material/IconButton";

const SignupModal = () => {
    const [cookies, setCookie] = useCookies(['ref']);
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useLayoutEffect(() => {
        try {
            const _refId = params.get('ref');

            if (_refId) {
                const expires = new Date()
                expires.setDate(expires.getDate() + 2)

                setCookie('ref', _refId, {
                    expires: expires,
                    maxAge: 604800,
                    path: '/',
                    domain: '.stashbunny.com'
                });
            }
        } catch (err: any) {
            Bugsnag.notify(err);
            console.log(err)
        }
    }, [params])

    return (
        <SignupProvider>
            <Content/>
        </SignupProvider>
    );
}

const Content = () => {
    const {back} = useBackRoute();
    const isMobile = useResponsive('down', 'md');
    const {showOptions} = useSignup();

    return (
        <>
            <div className="theme-backdrop !z-[1000]"/>

            <Popup opened={true}
                   backdrop={false}
                   className="md:!rounded-2xl !z-[1100]">
                <Page className="theme-surface">
                    <Navbar
                        className="fixed top-0 left-0"
                        colors={{bgIos: 'hairline-b-none '}}
                        translucent
                        right={
                            <IconButton onClick={back} className="!mt-1.5">
                                <Iconify icon="mingcute:close-line" />
                            </IconButton>
                        }
                    />

                    <div className="h-full flex items-center justify-center">
                        {showOptions ? <SignupOptions/> : <SignupEmail/>}
                    </div>
                </Page>
            </Popup>
        </>
    )
}

export default SignupModal;
