import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {PaidMembershipRequest} from '../../../models/PaidMembershipRequest';
import {useMutation, useQueryClient} from 'react-query';
import {userService} from '../../../services/UserService';
import React, {useContext, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import CreatorProfileContext from "../../../context/CreatorProfileConfigContext";
import PaginatorDot from "../../shared/PaginatorDot";
import {CREATOR_TOTAL_STEPS} from "../../../pages/CreatorProfileConvertPage";
import Currency from "../../shared/components/Currency";
import {CreatorAccount} from "../../../models/CreatorAccount";
import {useSnackbar} from "notistack";
import useBackRoute from "../../../hooks/useBackRoute";
import Bugsnag from "@bugsnag/js";
import {List, ListInput, ListItem, Navbar, NavbarBackLink, Toggle} from "konsta/react";
import {KonstaLoadingButton} from "../../../shared/components/KonstaLoadingButton";

type PaidMembershipFormProps = {
    dialogMode?: boolean;
}

const PaidMembershipForm = ({dialogMode}: PaidMembershipFormProps) => {
    const {t} = useTranslation();
    const {back} = useBackRoute();
    const {enqueueSnackbar} = useSnackbar();
    const {state, setState} = useContext(CreatorProfileContext);
    const queryClient = useQueryClient();

    const {control, watch, handleSubmit, register, formState, getValues, setValue} = useForm<PaidMembershipRequest>({
        mode: 'onChange',
        defaultValues: {
            enabled: state.profile.paidMembership?.enabled,
            amount: state.profile.paidMembership?.amount,
            quarterlyPlanDiscount: state.profile.paidMembership?.quarterlyPlanDiscount,
            semiannualPlanDiscount: state.profile.paidMembership?.semiannualPlanDiscount
        }
    });
    const {errors, isValid} = formState;
    const enabled = watch('enabled');
    const [saving, setSaving] = useState(false);

    const handleFormSubmit = (event) => {
        event.preventDefault()
        handleSubmit(onSubmit)(event)
    }

    const mutation = useMutation((formData: PaidMembershipRequest) => userService.savePaidMembership(formData))

    const onSubmit: SubmitHandler<PaidMembershipRequest> = async (formData) => {
        if (saving) return;
        try {
            setSaving(true);

            mutation.mutate(formData, {
                onSuccess: (data, variables, context) => {
                    queryClient.setQueryData('creator_profile', (oldData: CreatorAccount | undefined) => {
                        return {
                            ...oldData || {} as CreatorAccount,
                            paidMembership: formData
                        } as CreatorAccount
                    })

                    if (dialogMode) {
                        enqueueSnackbar(t('data_saved_successfully'), {variant: 'default'});
                        back();
                    } else {
                        setState({...state, step: 'bank-data'});
                    }
                    setSaving(false);
                },
                onError: (error: any) => {
                    setSaving(false);
                    Bugsnag.notify(error);
                    enqueueSnackbar(t(`Erro ao salvar dados do plano de assinatura.`), {variant: 'error'});
                    console.log(`Error at saving getting paid data: ${error.message}`);
                }
            });
        } catch (error: any) {
            setSaving(false);
            Bugsnag.notify(error);
            enqueueSnackbar(t(`Error at saving getting paid data: ${error.message}`), {variant: 'error'});
            console.log(`Error at saving getting paid data: ${error.message}`);
        }
    };

    const goToPrev = () => setState({...state, step: 'adult-content'});

    const calcQuarterlyPlanMonthlyAmount = () => {
        return getValues("amount") - (getValues("quarterlyPlanDiscount") * (getValues("amount") / 100));
    }

    const calcSemiannualPlanMonthlyAmount = () => {
        return getValues("amount") - (getValues("semiannualPlanDiscount") * (getValues("amount") / 100));
    }

    return (
        <>
            <Navbar
                left={
                    <NavbarBackLink showText={false} onClick={goToPrev} className="theme-on-surface ml-2"/>
                }
                right={
                    <KonstaLoadingButton clear
                                         loading={saving}
                                         disabled={!isValid}
                                         onClick={handleFormSubmit}>
                        {dialogMode ? t('save') : t('next')}
                    </KonstaLoadingButton>
                }
            />

            <div className="p-4">
                <div className="text-center">
                    {!dialogMode && (
                        <PaginatorDot className="mb-4" curPage={3} maxPage={CREATOR_TOTAL_STEPS}/>)}
                    <div className="text-3xl leading-8 font-medium tracking-tight">
                        {t('monthly_subscription')}
                    </div>
                    <div className="mt-2 max-w-2xl font-light text-base">
                        {t(
                            'Habilite o plano de assinatura mensal para membros, é a melhor forma de monetizar seu trabalho.'
                        )}
                    </div>
                </div>

                <form id="id-paid-membership-form" onSubmit={handleSubmit(onSubmit)}>
                    <List>
                        <Controller
                            control={control}
                            {...register('enabled')}
                            name="enabled"
                            render={({field}) => (
                                <ListItem
                                    label
                                    title={t('enable_subscription_plan_for_members')}
                                    after={
                                        <Toggle
                                            component="div"
                                            className="-my-1"
                                            checked={field.value}
                                            name={field.name}
                                            onChange={field.onChange}
                                        />
                                    }
                                />
                            )}
                        />
                    </List>
                    <List>
                        {enabled && (
                            <>
                                <Controller
                                    control={control}
                                    {...register('amount', {
                                        min: 5,
                                    })}
                                    name="amount"
                                    render={({field: {value, onChange, ...restProps}}) => {

                                        const konstaUITextFieldProps = {
                                            outline: true,
                                            label: t('monthly_subscription_amount'),
                                            value: value
                                        }

                                        return (
                                            <NumericFormat
                                                onValueChange={(values) => {
                                                    const {formattedValue, value, floatValue} = values;
                                                    if (floatValue) {
                                                        setValue('amount', floatValue, {shouldValidate: true})
                                                    }
                                                }}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix="R$ "
                                                decimalScale={2}
                                                valueIsNumericString={true}
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                customInput={ListInput}
                                                {...konstaUITextFieldProps}
                                                {...restProps}
                                                error={
                                                    errors.amount && errors.amount.type === 'required' ? (
                                                        t('please_enter_the_amount')
                                                    ) : errors.amount && errors.amount.type === 'min' ? (
                                                        t('the_minimum_amount_is', {
                                                            value: 5,
                                                            formatParams: {value: {currency: 'BRL'}}
                                                        })
                                                    ) : null
                                                }
                                                className="block text-base appearance-none w-full focus:outline-none bg-transparent h-10 placeholder-black placeholder-opacity-30 dark:placeholder-white dark:placeholder-opacity-30"
                                                placeholder="0.00"
                                                autoComplete="off"
                                            />
                                        )
                                    }}
                                />

                                <Controller
                                    control={control}
                                    {...register('quarterlyPlanDiscount', {
                                        min: 0,
                                        max: 20
                                    })}
                                    name="quarterlyPlanDiscount"
                                    render={({field: {value, onChange, ...restProps}}) => {

                                        const konstaUITextFieldProps = {
                                            outline: true,
                                            label: t('quarterly_plan_discount'),
                                            value: value
                                        }

                                        return (
                                            <NumericFormat
                                                onValueChange={(values) => {
                                                    const {formattedValue, value, floatValue} = values;
                                                    if (floatValue) {
                                                        setValue('quarterlyPlanDiscount', floatValue, {shouldValidate: true})
                                                    }
                                                }}
                                                prefix=""
                                                suffix="%"
                                                decimalScale={0}
                                                valueIsNumericString={true}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                autoFocus={true}
                                                autoComplete="off"
                                                customInput={ListInput}
                                                {...konstaUITextFieldProps}
                                                {...restProps}
                                                info={
                                                    getValues("quarterlyPlanDiscount") ? (
                                                        <span className="inline-flex gap-x-1">
                                                   <Currency value={calcQuarterlyPlanMonthlyAmount()} currency="BRL"
                                                             className="font-bold"/> por mês e <Currency
                                                            value={calcQuarterlyPlanMonthlyAmount() * 3} currency="BRL"
                                                            className="font-bold"/> no total de 3 meses.
                                               </span>
                                                    ) : t('Quando em branco esse plano será desconsiderado.')
                                                }
                                                error={
                                                    errors.quarterlyPlanDiscount && errors.quarterlyPlanDiscount.type === 'max' ? (
                                                        t('the_maximum_discount_per_plan_is', {value: 20})
                                                    ) : null
                                                }
                                                className="block text-base appearance-none w-full focus:outline-none bg-transparent  h-10 placeholder-black placeholder-opacity-30 dark:placeholder-white dark:placeholder-opacity-30"
                                                placeholder="0.00"
                                            />
                                        )
                                    }}
                                />

                                <Controller
                                    control={control}
                                    {...register('semiannualPlanDiscount', {
                                        min: 0,
                                        max: 20
                                    })}
                                    name="semiannualPlanDiscount"
                                    render={({field: {value, onChange, ...restProps}}) => {

                                        const konstaUITextFieldProps = {
                                            outline: true,
                                            label: t('semiannual_plan_discount'),
                                            value: value
                                        }

                                        return (
                                            <NumericFormat
                                                onValueChange={(values) => {
                                                    const {formattedValue, value, floatValue} = values;
                                                    if (floatValue) {
                                                        setValue('semiannualPlanDiscount', floatValue, {shouldValidate: true})
                                                    }
                                                }}
                                                prefix=""
                                                suffix="%"
                                                decimalScale={0}
                                                valueIsNumericString={true}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                autoFocus={true}
                                                autoComplete="off"
                                                customInput={ListInput}
                                                {...konstaUITextFieldProps}
                                                {...restProps}
                                                info={
                                                    getValues("semiannualPlanDiscount") ? (
                                                        <span className="inline-flex gap-x-1"
                                                              id="email-description">
                                                   <Currency value={calcSemiannualPlanMonthlyAmount()} currency="BRL"
                                                             className="font-bold"/> por mês e <Currency
                                                            value={calcSemiannualPlanMonthlyAmount() * 6} currency="BRL"
                                                            className="font-bold"/> no total de 6 meses.
                                               </span>
                                                    ) : t('Quando em branco esse plano será desconsiderado.')
                                                }
                                                error={
                                                    errors.semiannualPlanDiscount && errors.semiannualPlanDiscount.type === 'max' ? (
                                                        t('the_maximum_discount_per_plan_is', {value: 20})
                                                    ) : null
                                                }
                                                className="block text-base appearance-none w-full focus:outline-none bg-transparent  h-10 placeholder-black placeholder-opacity-30 dark:placeholder-white dark:placeholder-opacity-30"
                                                placeholder="0.00"
                                            />
                                        )
                                    }}
                                />
                            </>
                        )}
                    </List>
                </form>
            </div>
        </>
    )
}

export default PaidMembershipForm;
