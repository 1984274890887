import {useMemo} from 'react';
import merge from 'lodash/merge';

import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';

import useLocales from "../hooks/useLocales";
// import { useLocales } from 'src/locales';
// system
import {palette} from './palette';
import {shadows} from './shadows';
import {typography} from './typography';
// options
import {customShadows} from './custom-shadows';
import {componentsOverrides} from './overrides';
import {createContrast} from './options/contrast';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { currentLang } = useLocales();

  const savedDarkMode = localStorage.getItem('theme');
  const themeMode = savedDarkMode !== null && savedDarkMode === 'dark' ? 'dark' : 'light';

  const contrast = createContrast('default', themeMode);

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette(themeMode),
        ...contrast.palette,
      },
      customShadows: {
        ...customShadows(themeMode),
      },
      // direction: '',
      shadows: shadows(themeMode),
      shape: { borderRadius: 8 },
      typography,
    }),
    [
      themeMode,
    ]
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = merge(componentsOverrides(theme), contrast.components);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );

  return (
    <MuiThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        {children}
    </MuiThemeProvider>
  );
}
