import React, {Suspense, useEffect, useLayoutEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
// layouts
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from "../hooks/useAuth";

import {PATH_AUTH} from "./paths";
import CreatorAccountCategoriesDialog from '../components/creator-account-settings/CreatorAccountCategoriesDialog';
import CreatorAccountAdultContentDialog from '../components/creator-account-settings/CreatorAccountAdultContentDialog';
import CreatorAccountBankDataDialog from '../components/creator-account-settings/CreatorAccountBankDataDialog';
import MainLayout from '../layouts/main';
import FullLayout from '../layouts/full';
import PaymentLayout from "../layouts/payment";
import lazyRetry from '../utils/lazyRetry';
import NoGuard from "../guards/NoGuard";
import Bugsnag from "@bugsnag/js";

const Loadable = (Component) => (props) => {
    const {pathname} = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
            <Component {...props} />
        </Suspense>
    );
};

// // AUTHENTICATION
const SignupModal = Loadable(lazyRetry(() => import('../components/auth/signup/SignupModal')));
const LoginModal = Loadable(lazyRetry(() => import('../components/auth/login/LoginModal')));
const ForgotPasswordPage = Loadable(lazyRetry(() => import('../pages/ForgotPasswordPage')));
// const VerifyCode = Loadable(lazyRetry(() => import('../pages/auth/VerifyCode')));
//
// // MAIN
const StashbunnyBrandPage = Loadable(lazyRetry(() => import('../pages/stashbunny-brand-page')));
const NewLandingPage = Loadable(lazyRetry(() => import('../pages/new-landing-page')));
const AboutPage = Loadable(lazyRetry(() => import('../pages/about-page')));
const SecurityAdvisoriesPage = Loadable(lazyRetry(() => import('../pages/security-advisories-page')));
const ContactPage = Loadable(lazyRetry(() => import('../pages/contact-page')));

const LandingPage = Loadable(lazyRetry(() => import('../pages/LandingPage')));
const ForYouPage = Loadable(lazyRetry(() => import('../pages/ForYouPage')));
const ExplorePage = Loadable(lazyRetry(() => import('../pages/ExplorePage')));
const HomePage = Loadable(lazyRetry(() => import('../pages/HomePage')));
const AccountSearchPage = Loadable(lazyRetry(() => import('../components/AccountSearchPage')));
const NotificationsPage = Loadable(lazyRetry(() => import('../pages/NotificationsPage')));
const MyReferralsPage = Loadable(lazyRetry(() => import('../pages/MyReferralsPage')));
const MyEarningsPage = Loadable(lazyRetry(() => import('../pages/MyEarningsPage')));
const DashboardPage = Loadable(lazyRetry(() => import('../pages/DashboardPage')));
const ResourcesCoursesPage = Loadable(lazyRetry(() => import('../pages/ResourcesCoursesPage')));
const AccountsSuggestionsPage = Loadable(lazyRetry(() => import('../pages/AccountsSuggestionsPage')));
const AccountsFollowingPage = Loadable(lazyRetry(() => import('../pages/AccountsFollowingPage')));
const PostsPage = Loadable(lazyRetry(() => import('../pages/PostsPage')));
const ExplorePostsPage = Loadable(lazyRetry(() => import('../pages/ExplorePostsPage')));
const ExplorePostsTagsPage = Loadable(lazyRetry(() => import('../pages/ExplorePostsTagsPage')));
const ExplorePacksTagsPage = Loadable(lazyRetry(() => import('../pages/ExplorePacksTagsPage')));
const MySubscriptionsPage = Loadable(lazyRetry(() => import('../pages/MySubscriptionsPage')));
const SubscriptionPage = Loadable(lazyRetry(() => import('../pages/SubscriptionPage')));
const PurchasedPacksPage = Loadable(lazyRetry(() => import('../pages/PurchasedPacksPage')));
const PacksExplorePage = Loadable(lazyRetry(() => import('../pages/PacksExplorePage')));
const PageNotFoundPage = Loadable(lazyRetry(() => import('../pages/PageNotFoundPage')));
const SubscriptionPlansPage = Loadable(lazyRetry(() => import("../pages/SubscriptionPlansPage")));
const CampaignPageV2 = Loadable(lazyRetry(() => import("../pages/CampaignPage")));

const ProfilePage = Loadable(lazyRetry(() => import('../pages/ProfilePage')));
const ProfilePageV2 = Loadable(lazyRetry(() => import('../pages/ProfilePageV2')));
const RevenuePage = Loadable(lazyRetry(() => import('../pages/RevenuePage')));

// // POSTS
const PostPage = Loadable(lazyRetry(() => import('../pages/PostPage')));
const PostViewerPage = Loadable(lazyRetry(() => import('../pages/PostViewerPage')));
const PostCommentsPage = Loadable(lazyRetry(() => import('../pages/PostCommentsPage')));
const CreatePostPage = Loadable(lazyRetry(() => import('../pages/CreatePostPage')));
const CreatePackPage = Loadable(lazyRetry(() => import('../pages/CreatePackPage')));
const PostViewerDialog = Loadable(lazyRetry(() => import('../components/posts/PostViewerDialog')));
const CreatePostDialog = Loadable(lazyRetry(() => import('../components/create/CreatePostDialog')));

// // PACKS
const ArchivedPage = Loadable(lazyRetry(() => import('../pages/ArchivedPage')));
const LaunchesPage = Loadable(lazyRetry(() => import('../pages/LaunchesPage')));
const LaunchDetailPage = Loadable(lazyRetry(() => import('../pages/LaunchDetailPage')));
const PackViewerPage = Loadable(lazyRetry(() => import('../pages/PackViewerPage')));
const CreatePackDialog = Loadable(lazyRetry(() => import('../components/create-pack/CreatePackDialog')));

// // SETTINGS
const SettingsPage = Loadable(lazyRetry(() => import('../pages/SettingsPage')));
const SettingsAccountBasicInfoEdit = Loadable(lazyRetry(() => import('../pages/SettingsAccountBasicInfoEdit')));
const SettingsAccountNotificationsPage = Loadable(lazyRetry(() => import('../pages/SettingsAccountNotificationsPage')));
const SettingsMonetization = Loadable(lazyRetry(() => import('../pages/SettingsMonetization')));
const CreatorAccountPaidMembershipDialog = Loadable(lazyRetry(() => import('../components/creator-account-settings/CreatorAccountPaidMembershipDialog')));
const SettingsPrivacyAndSafetyPage = Loadable(lazyRetry(() => import('../pages/SettingsPrivacyAndSafetyPage')));
const SettingsContentYouSeePage = Loadable(lazyRetry(() => import('../pages/SettingsContentYouSeePage')));
const SettingsDeactivateOrDeletePage = Loadable(lazyRetry(() => import('../pages/SettingsDeactivateOrDeletePage')));
const SettingsDeactivatePage = Loadable(lazyRetry(() => import('../pages/SettingsDeactivatePage')));
const AccountDeactivatedPage = Loadable(lazyRetry(() => import('../pages/AccountDeactivatedPage')));
const AccountDeletedPage = Loadable(lazyRetry(() => import('../pages/AccountDeletedPage')));
const SettingsDeleteAccountPage = Loadable(lazyRetry(() => import('../pages/SettingsDeleteAccountPage')));
const SettingsBankDetailsPage = Loadable(lazyRetry(() => import('../pages/SettingsBankDetailsPage')));
const SettingsAccountPage = Loadable(lazyRetry(() => import('../pages/SettingsAccountPage')));
const SettingsSubscriptionPlanPage = Loadable(lazyRetry(() => import('../pages/SettingsSubscriptionPlanPage')));
const SettingsCategoriesPage = Loadable(lazyRetry(() => import('../pages/SettingsCategoriesPage')));
const SettingsAdultContentPage = Loadable(lazyRetry(() => import('../pages/SettingsAdultContentPage')));
const SettingsAccountIdentityAndStatusPage = Loadable(lazyRetry(() => import('../pages/SettingsAccountIdentityAndStatusPage')));
const SettingsDisplayAndLanguagesPage = Loadable(lazyRetry(() => import('../pages/SettingsDisplayAndLanguagesPage')));
const CreatorAccountSettingsDialog = Loadable(lazyRetry(() => import('../components/creator-account-settings/CreatorAccountSettingsDialog')));
const IdentityConfirmDialog = Loadable(lazyRetry(() => import('../components/identity-confirm/IdentityConfirmDialog')));
const CreateStoryDialog = Loadable(lazyRetry(() => import('../components/story/create/CreateStoryDialog')));
const ScheduledContentPage = Loadable(lazyRetry(() => import('../pages/ScheduledContentPage')));

// // DIRECT
const MessagesPage = Loadable(lazyRetry(() => import('../pages/MessagesPage')));
const NewBroadcastPage = Loadable(lazyRetry(() => import('../pages/NewBroadcastPage')));

// // PAYMENT
const CheckoutPaymentPage = Loadable(lazyRetry(() => import('../components/payment/CheckoutPaymentPage')));
const PIXPaymentDialog = Loadable(lazyRetry(() => import('../components/payment/PIXPaymentDialog')));
const PIXPaymentSuccessDialog = Loadable(lazyRetry(() => import('../components/payment/PIXPaymentSuccessDialog')));
const PIXPaymentPendingDialog = Loadable(lazyRetry(() => import('../components/payment/PIXPaymentPendingDialog')));
const PaymentRejectedDialog = Loadable(lazyRetry(() => import('../components/payment/PaymentRejectedDialog')));

const HelpPage = Loadable(lazyRetry(() => import('../pages/HelpPage')));
const PrivacyPolicyPage = Loadable(lazyRetry(() => import('../pages/PrivacyPolicyPage')));
const TermsOfServicePage = Loadable(lazyRetry(() => import('../pages/TermsOfServicePage')));
const CookiePolicyPage = Loadable(lazyRetry(() => import('../pages/CookiePolicyPage')));
const IntellectualPropertyPage = Loadable(lazyRetry(() => import('../pages/IntellectualPropertyPage')));
const HelpViewCountsPage = Loadable(lazyRetry(() => import('../pages/HelpViewCountsPage')));
const HelpAboutVerifiedAccountsPage = Loadable(lazyRetry(() => import('../pages/HelpAboutVerifiedAccountsPage')));
const CampaignPage = Loadable(lazyRetry(() => import('../components/promotion/Page')));
const CreateStoryPage = Loadable(lazyRetry(() => import('../pages/CreateStoryPage')));

// ----------------------------------------------------------------------

export default function Router() {
    const {user, isAuthenticated, isInitialized} = useAuth();
    const location = useLocation()

    // The `backgroundLocation` state is the location that we were at when one of
    // the gallery links was clicked. If it's there, use it as the location for
    // the <Routes> so we show the gallery in the background, behind the modal.
    const state = location.state as { backgroundLocation?: Location };

    useEffect(() => {
        if (user) {
            Bugsnag.setUser(user.id);
        }
    }, [user])

    useLayoutEffect(() => {
        const savedDarkMode = localStorage.getItem('theme')

        if (savedDarkMode !== null && savedDarkMode === 'dark') {
            document.documentElement.classList.add('dark')
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', 'rgb(18 18 18)')
        } else {
            document.documentElement.classList.remove('dark')
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#fff')
        }
    }, [])

    // useEffect(() => {
    //     console.info('Location is changed.')
    //
    //     if ('serviceWorker' in navigator) {
    //         navigator.serviceWorker.ready.then(value => {
    //             navigator.serviceWorker.getRegistration()
    //                 .then(registration => {
    //                     if (registration) {
    //                         registration.update()
    //                             .then(() => {
    //                                 console.log('Service Worker update check completed.');
    //
    //                                 if (registration.waiting) {
    //                                     console.log('Skiping service-work')
    //                                     registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    //                                 }
    //                             })
    //                             .catch(error => {
    //                                 console.error('Service Worker update check failed:', error);
    //                             });
    //                     } else {
    //                         console.log('No Service Worker registered.');
    //                     }
    //                 })
    //                 .catch(error => {
    //                     console.error('Service Worker registration retrieval failed:', error);
    //                 });
    //         });
    //     } else {
    //         console.log('Service Worker is not supported in this browser.');
    //     }
    // }, [location]);

    return (
        <>
            <Routes location={state?.backgroundLocation || location}>
                <Route path={PATH_AUTH.register} element={<GuestGuard><SignupModal /></GuestGuard>}/>
                <Route path={PATH_AUTH.login} element={<GuestGuard><LoginModal /></GuestGuard>}/>
                <Route path={PATH_AUTH.resetPassword} element={<GuestGuard><ForgotPasswordPage /></GuestGuard>}/>

                {isInitialized && !isAuthenticated ? (
                    <Route element={<LandingPage/>} index={true} />

                    // <Route path="/">
                    //     <Route element={<NewLandingPage/>} index={true} />
                    // </Route>
                ) : (
                    <Route path="/" element={<AuthGuard><MainLayout newMobile={true} /></AuthGuard>}>
                        <Route element={<HomePage/>} index={true} />
                    </Route>
                )}

                <Route path="/" element={<AuthGuard><MainLayout /></AuthGuard>}>
                    <Route path='explore-v2' element={<ForYouPage/>}/>
                    <Route path='explore/search' element={<AccountSearchPage/>}/>
                    <Route path='notifications' element={<NotificationsPage/>}/>
                    <Route path='referrals' element={<MyReferralsPage/>}/>
                    <Route path='my-earnings' element={<MyEarningsPage/>}/>
                    <Route path='accounts/suggestions/' element={<AccountsSuggestionsPage/>}/>
                    <Route path='accounts/following/' element={<AccountsFollowingPage/>}/>
                    <Route path="posts/:id" element={<PostsPage/>}/>
                    <Route path="explore/posts/:id" element={<ExplorePostsPage/>}/>
                    <Route path="explore/posts/tags/:tag" element={<ExplorePostsTagsPage/>}/>
                    <Route path="explore/packs/tags/:tag" element={<ExplorePacksTagsPage/>}/>
                    <Route path="subscriptions/" element={<MySubscriptionsPage/>}/>
                    <Route path="subscriptions/:id" element={<SubscriptionPage/>}/>
                    <Route path="packs/purchased" element={<PurchasedPacksPage/>}/>
                    <Route path="packs/explore" element={<PacksExplorePage/>}/>
                    <Route path='explore' element={<ExplorePage/>}/>
                    <Route path='stories/create' element={<CreateStoryPage/>}/>

                    <Route path='launches' element={<LaunchesPage/>}/>
                    <Route path='launches/:id' element={<LaunchDetailPage/>}/>

                    <Route path="campaign/creators" element={<CampaignPage />}/>
                </Route>

                <Route path="/" element={<AuthGuard><MainLayout newMobile={true} /></AuthGuard>}>
                    <Route path='scheduled-content' element={<ScheduledContentPage/>}/>
                </Route>

                <Route path="/" element={<AuthGuard><MainLayout/></AuthGuard>}>
                    <Route path='dashboard' element={<DashboardPage/>}/>
                    <Route path='resources/courses' element={<ResourcesCoursesPage/>}/>
                </Route>

                <Route path="/about" element={<NoGuard><AboutPage/></NoGuard>}/>
                <Route path="/resources/stashbunny-brand/" element={<NoGuard><StashbunnyBrandPage/></NoGuard>}/>
                <Route path="/security/advisories" element={<NoGuard><SecurityAdvisoriesPage/></NoGuard>}/>
                <Route path="/contact" element={<NoGuard><ContactPage/></NoGuard>}/>

                <Route path="/account-deactivated" element={<GuestGuard><AccountDeactivatedPage/></GuestGuard>}/>
                <Route path="/account-deleted" element={<GuestGuard><AccountDeletedPage/></GuestGuard>}/>

                <Route path=':username' element={<NoGuard><MainLayout newMobile={true} /></NoGuard>}>
                    <Route element={<ProfilePage/>} index={true}/>
                    <Route path="posts/:id" element={<PostsPage/>}/>
                </Route>

                <Route path=':username' element={<NoGuard><MainLayout/></NoGuard>}>
                    <Route path="l" element={<ProfilePageV2/>}/>
                </Route>

                <Route path=':username/l/:id' element={<NoGuard><CampaignPageV2/></NoGuard>}/>

                {/* Posts */}
                <Route path='post' element={<AuthGuard><MainLayout newMobile={true}/></AuthGuard>}>
                    <Route path=':id' element={<PostViewerPage/>}/>
                    <Route path=':id/comments/' element={<PostCommentsPage/>}/>
                    <Route path='create' element={<CreatePostPage/>}/>
                </Route>

                <Route path='post' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                    <Route path=':id/media/:mediaIndex' element={<PostPage/>}/>
                </Route>

                {/* Packs */}
                <Route path='pack' element={<AuthGuard><MainLayout newMobile={true}/></AuthGuard>}>
                    <Route path='create' element={<CreatePackPage/>}/>
                </Route>

                <Route path='pack' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                    <Route path=':id' element={<PackViewerPage/>}/>
                </Route>

                {/* Settings */}
                <Route path='settings' element={<AuthGuard><FullLayout/></AuthGuard>}>
                    <Route element={<SettingsPage/>} index={true}/>
                    <Route path='profile' element={<SettingsAccountBasicInfoEdit/>}/>
                    <Route path='account' element={<SettingsAccountPage/>}/>
                    <Route path='categories' element={<SettingsCategoriesPage/>}/>
                    <Route path='adult_content' element={<SettingsAdultContentPage/>}/>
                    <Route path='account_identity_and_status' element={<SettingsAccountIdentityAndStatusPage/>}/>
                    <Route path='notification' element={<SettingsAccountNotificationsPage/>}/>
                    <Route path='monetization' element={<SettingsMonetization/>}/>
                    <Route path='subscription_plan' element={<SettingsSubscriptionPlanPage/>}/>
                    <Route path='bank_details' element={<SettingsBankDetailsPage/>}/>
                    <Route path='privacy_and_safety' element={<SettingsPrivacyAndSafetyPage/>}/>
                    <Route path='content_you_see' element={<SettingsContentYouSeePage/>}/>
                    <Route path='display_and_languages' element={<SettingsDisplayAndLanguagesPage/>}/>
                    <Route path='deactivate_or_delete' element={<SettingsDeactivateOrDeletePage/>}/>
                    <Route path='deactivate' element={<SettingsDeactivatePage/>}/>
                    <Route path='delete_account' element={<SettingsDeleteAccountPage/>}/>
                    <Route path='convert_to_creator_account' element={<CreatorAccountSettingsDialog/>}/>
                    <Route path='identity_confirm' element={<IdentityConfirmDialog/>}/>
                </Route>

                {/* Direct */}
                <Route path='direct' element={<AuthGuard><FullLayout/></AuthGuard>}>
                    <Route path='inbox' element={<MessagesPage/>}/>
                    <Route path='broadcast' element={<NewBroadcastPage/>}/>
                    <Route path='t/:thread_id' element={<MessagesPage/>}/>
                </Route>

                {/* Payment */}
                <Route path='payment' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                    <Route path=':id' element={<CheckoutPaymentPage/>}/>
                    <Route path='pix/:id' element={<PIXPaymentDialog/>}/>
                    <Route path=':id/success' element={<PIXPaymentSuccessDialog/>}/>
                    <Route path=':id/pending' element={<PIXPaymentPendingDialog/>}/>
                    <Route path=':id/rejected' element={<PaymentRejectedDialog/>}/>
                </Route>

                {/* Content */}
                <Route path='content' element={<AuthGuard><MainLayout/></AuthGuard>}>
                    <Route path='archived' element={<ArchivedPage/>}/>
                </Route>

                {/* Revenue */}
                <Route path='profile' element={<AuthGuard><MainLayout/></AuthGuard>}>
                    <Route path='analytics' element={<RevenuePage/>}/>
                </Route>

                <Route path="help" element={<HelpPage/>}/>
                <Route path="legal/privacy" element={<PrivacyPolicyPage/>}/>
                <Route path="legal/terms" element={<TermsOfServicePage/>}/>
                <Route path="cookie-policy" element={<CookiePolicyPage/>}/>
                <Route path="https://support.stashbunny.com/intellectual-property" element={<IntellectualPropertyPage/>}/>
                <Route path="help/using-stashbunny/view-counts" element={<HelpViewCountsPage/>}/>
                <Route path="help/about-stashbunny-verified-accounts" element={<HelpAboutVerifiedAccountsPage/>}/>

                <Route path="*" element={<PageNotFoundPage/>}/>
            </Routes>

            {/* Show the modal when a `backgroundLocation` is set */}
            {state?.backgroundLocation && (
                <Routes>
                    <Route path={PATH_AUTH.register} element={<GuestGuard><SignupModal /></GuestGuard>}/>
                    <Route path={PATH_AUTH.login} element={<GuestGuard><LoginModal /></GuestGuard>}/>

                    <Route path=':username' element={<NoGuard><MainLayout newMobile={true} /></NoGuard>}>
                        <Route element={<ProfilePage/>} index={true}/>
                        <Route path="posts/:id" element={<PostsPage/>}/>
                    </Route>

                    <Route path=":username/subscription-plans" element={<SubscriptionPlansPage/>}/>
                    
                    {/* Posts */}
                    <Route path='post' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                        <Route path=':id' element={<PostPage onDeletePost={() => {
                        }}/>}/>
                        <Route path=':id/comments/' element={<PostCommentsPage/>}/>
                        <Route path=':id/media/:mediaIndex' element={<PostPage/>}/>
                        <Route path='create' element={<CreatePostDialog/>}/>
                    </Route>

                    {/* Packs */}
                    <Route path='pack' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                        <Route path=':id' element={<PackViewerPage />}/>
                        <Route path='create' element={<CreatePackDialog/>}/>
                    </Route>

                    {/* Stories */}
                    <Route path='stories' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                        <Route path='create' element={<CreateStoryDialog/>}/>
                    </Route>

                    {/* Settings */}
                    <Route path='settings' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                        <Route path='convert_to_creator_account' element={<CreatorAccountSettingsDialog/>}/>

                        <Route path="identity_confirm/"
                               element={<IdentityConfirmDialog/>}/>
                        <Route path="categories/"
                               element={<CreatorAccountCategoriesDialog/>}/>
                        <Route path="adult-content/"
                               element={<CreatorAccountAdultContentDialog/>}/>
                        <Route path="monetization/subscription_plan"
                               element={<CreatorAccountPaidMembershipDialog/>}/>
                        <Route path="bank-data/"
                               element={<CreatorAccountBankDataDialog/>}/>
                    </Route>

                    {/* Payment */}
                    <Route path='payment' element={<AuthGuard><PaymentLayout/></AuthGuard>}>
                        <Route path=':id/success' element={<PIXPaymentSuccessDialog/>}/>
                        <Route path=':id/pending' element={<PIXPaymentPendingDialog/>}/>
                        <Route path=':id/rejected' element={<PaymentRejectedDialog/>}/>
                    </Route>
                </Routes>
            )}
        </>
    );
}
