import {useTranslation} from 'react-i18next';
import React, {useContext, useEffect} from 'react';
import CreatorProfileContext from '../../context/CreatorProfileConfigContext';
import DialogContent from "@mui/material/DialogContent";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {useQuery} from "react-query";
import {userService} from "../../services/UserService";
import LoadingIndicator from "../shared/LoadingIndicator";
import useBackRoute from "../../hooks/useBackRoute";
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";
import {Button, Link, Navbar, NavbarBackLink} from "konsta/react";

const CreatorAccountWelcome = () => {
    const {t} = useTranslation();
    const {back} = useBackRoute();
    const {state, setState} = useContext(CreatorProfileContext);

    const {isLoading, data: creatorAccount} = useQuery(
        'creator_account',
        () => userService.getCreatorAccount()
    );

    useEffect(() => {
        if (creatorAccount) {
            setState({...state, profile: creatorAccount})
        }
    }, [creatorAccount])

    const goToNext = () => setState({...state, step: 'welcome-faq'})

    return (
        <>
            <Navbar
                left={
                    <NavbarBackLink text={t('not_now')} onClick={back} className="theme-on-surface ml-2"/>
                }
                right={
                    <Link navbar onClick={goToNext} className="mr-2 uppercase font-semibold text-sm">{t('continue')}</Link>
                }
            />

            <div className="p-4 flex flex-col items-center gap-y-4">
                <h1 className="text-3xl text-center font-bold">{t('become_a_content_creator')}</h1>

                {isLoading ? (
                    <div className="py-36">
                        <LoadingIndicator/>
                    </div>
                ) : (
                    <>
                        <List sx={{width: '100%'}} className="flex-1">
                            <ListItem>
                                <ListItemAvatar>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="w-9 text-primary"
                                         preserveAspectRatio="none"
                                         fill="currentColor"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M276.1 230.3C282.7 231.5 292.7 233.5 297.1 234.7C307.8 237.5 314.2 248.5 311.3 259.1C308.5 269.8 297.5 276.2 286.9 273.3C283 272.3 269.5 269.7 265.1 268.1C252.9 267.1 242.1 268.7 236.5 271.6C230.2 274.4 228.7 277.7 228.3 279.7C227.7 283.1 228.3 284.3 228.5 284.7C228.7 285.2 229.5 286.4 232.1 288.2C238.2 292.4 247.8 295.4 261.1 299.7L262.8 299.9C274.9 303.6 291.1 308.4 303.2 317.3C309.9 322.1 316.2 328.7 320.1 337.7C324.1 346.8 324.9 356.8 323.1 367.2C319.8 386.2 307.2 399.2 291.4 405.9C286.6 407.1 281.4 409.5 276.1 410.5V416C276.1 427.1 267.1 436.1 255.1 436.1C244.9 436.1 235.9 427.1 235.9 416V409.6C226.4 407.4 213.1 403.2 206.1 400.5C204.4 399.9 202.9 399.4 201.7 398.1C191.2 395.5 185.5 384.2 189 373.7C192.5 363.2 203.8 357.5 214.3 361C216.3 361.7 218.5 362.4 220.7 363.2C230.2 366.4 240.9 370 246.9 371C259.7 373 269.6 371.7 275.7 369.1C281.2 366.8 283.1 363.8 283.7 360.3C284.4 356.3 283.8 354.5 283.4 353.7C283.1 352.8 282.2 351.4 279.7 349.6C273.8 345.3 264.4 342.2 250.4 337.9L248.2 337.3C236.5 333.8 221.2 329.2 209.6 321.3C203 316.8 196.5 310.6 192.3 301.8C188.1 292.9 187.1 283 188.9 272.8C192.1 254.5 205.1 241.9 220 235.1C224.1 232.9 230.3 231.2 235.9 230V223.1C235.9 212.9 244.9 203.9 256 203.9C267.1 203.9 276.1 212.9 276.1 223.1L276.1 230.3zM179 120.4L121.9 37.62C110.1 21.7 122.4 0 141.7 0H370.3C389.6 0 401 21.7 390.1 37.62L332.1 120.4C341.2 125.7 350.8 132.1 361.2 139.9C422.6 185.5 512 274.8 512 416C512 469 469 512 416 512H96C42.98 512 0 469 0 416C0 274.8 89.41 185.5 150.8 139.9C161.2 132.1 170.8 125.7 179 120.4L179 120.4zM204.9 160.8C150.9 195.4 48 277 48 416C48 442.5 69.49 464 96 464H416C442.5 464 464 442.5 464 416C464 277 361.1 195.4 307.1 160.8L293.3 151.1H218.7L204.9 160.8zM285.1 103.1L324.6 47.1H187.4L226 103.1H285.1z"/>
                                    </svg>
                                </ListItemAvatar>
                                <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                              primary={t('Monetize e seja livre')}
                                              secondary={t('become_a_creator_description')}/>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="w-9 text-primary"
                                         preserveAspectRatio="none"
                                         fill="currentColor"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M360 144h-208C138.8 144 128 154.8 128 168S138.8 192 152 192h208C373.3 192 384 181.3 384 168S373.3 144 360 144zM264 240h-112C138.8 240 128 250.8 128 264S138.8 288 152 288h112C277.3 288 288 277.3 288 264S277.3 240 264 240zM447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.836 11.02 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM464 352c0 8.75-7.25 16-16 16h-160l-80 60v-60H64c-8.75 0-16-7.25-16-16V64c0-8.75 7.25-16 16-16h384c8.75 0 16 7.25 16 16V352z"/>
                                    </svg>
                                </ListItemAvatar>
                                <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                              primary={t('professional_chat')}
                                              secondary={t('professional_chat_description')}/>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="w-9 text-primary"
                                         preserveAspectRatio="none"
                                         fill="currentColor"
                                         viewBox="0 0 640 512">
                                        <path
                                            d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM319.9 160c30.85 0 55.96 25.12 55.96 56S350.7 272 319.9 272S263.9 246.9 263.9 216S289 160 319.9 160zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM178.1 464c10.47-36.76 47.36-64 91.14-64H369.9c43.77 0 80.66 27.24 91.14 64H178.1zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160z"/>
                                    </svg>
                                </ListItemAvatar>
                                <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                              primary={t('your_fans_closer')}
                                              secondary={t('your_fans_closer_description')}/>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="w-9 text-primary"
                                         preserveAspectRatio="none"
                                         fill="currentColor"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M466.5 83.71l-192-80c-5.875-2.5-12.16-3.703-18.44-3.703S243.5 1.203 237.6 3.703L45.61 83.71C27.73 91.08 16 108.6 16 127.1C16 385.4 205.4 512 255.9 512C305.2 512 496 387.3 496 127.1C496 108.6 484.3 91.08 466.5 83.71zM256 464C158.5 423.4 64 297.3 64 128l192-80L448 128C448 301.8 349.6 425 256 464zM200.1 247C196.3 242.3 190.1 239.1 184 239.1c-13.71 0-24 11.21-24 24C160 270.1 162.3 276.3 167 280.1l48 48C219.5 333.5 225.7 335.1 232 335.1c2.595 0 11.46-.4962 18.22-8.375l96-112c3.881-4.528 5.781-10.09 5.781-15.62c0-7.405-5.79-23.99-23.98-23.99c-6.756 0-13.48 2.831-18.24 8.362L230.7 276.7L200.1 247z"/>
                                    </svg>
                                </ListItemAvatar>
                                <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                              primary={t('your_safety_is_a_priority')}
                                              secondary={t('your_safety_is_a_priority_description')}/>
                            </ListItem>
                        </List>
                    </>
                )}

                {/*<div className="z-10 theme-surface left-0 right-0 bottom-0 fixed py-2 px-2 hairline-t">*/}
                {/*    <KonstaLoadingButton rounded large loading={isProcessing}*/}
                {/*        // disabled={!isValid}*/}
                {/*                         onClick={handleFormSubmit}>*/}
                {/*        {t('give_feedback')}*/}
                {/*    </KonstaLoadingButton>*/}
                {/*</div>*/}
            </div>
        </>
    );
}

export default CreatorAccountWelcome;
