import * as React from 'react';

const LogotypeVerticalIcon = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.89 177.83" className={props.className}>
            <defs>
                <clipPath id="_clipPath_d4mn0tsR9l9nC9lrhzjb2vA2xfj8KjWt">
                    <rect width="301.89" height="177.83"/>
                </clipPath>
            </defs>
            <g clipPath="url(#_clipPath_d4mn0tsR9l9nC9lrhzjb2vA2xfj8KjWt)">
                <path d=" M 0.21 165.41 L 0.21 161.41 C 2.865 162.811 5.809 163.574 8.81 163.64 C 12.26 163.64 15.18 162.16 15.18 159.64 C 15.18 157.52 13.54 156.51 11.36 155.92 L 6.64 154.6 C 1.97 153.33 0 150.78 0 146.74 C 0 141.96 4.68 139.15 10.15 139.15 C 12.777 139.095 15.374 139.719 17.69 140.96 L 17.69 144.96 C 15.284 143.688 12.601 143.032 9.88 143.05 C 7.17 143.05 4.46 144.16 4.46 146.82 C 4.46 148.57 5.74 149.69 8.29 150.38 L 12.48 151.6 C 17.85 152.93 19.6 155.69 19.6 159.6 C 19.6 164.75 14.87 167.46 8.5 167.46 C 5.46 167.48 1.75 166.47 0.21 165.41 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 40.88 162.96 L 40.88 166.84 C 39.323 167.271 37.715 167.486 36.1 167.48 C 29.1 167.48 25.96 162.54 25.96 156.01 L 25.96 135.24 L 29.68 132.11 L 30.32 132.11 L 30.32 139.76 L 40.25 139.76 L 40.25 143.47 L 30.32 143.47 L 30.32 155.9 C 30.32 159.83 31.86 163.55 36.74 163.55 C 38.139 163.53 39.531 163.332 40.88 162.96 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 66.85 139.76 L 71.21 139.76 L 71.21 166.84 L 66.85 166.84 L 66.85 164.03 C 64.265 166.286 60.941 167.513 57.51 167.48 C 50.23 167.48 44.02 162.01 44.02 153.3 C 44.02 144.59 50.23 139.12 57.51 139.12 C 60.941 139.087 64.265 140.314 66.85 142.57 L 66.85 139.76 Z  M 66.85 159.76 L 66.85 146.87 C 64.551 144.487 61.401 143.113 58.09 143.05 C 52.99 143.05 48.58 146.87 48.48 153.3 C 48.58 159.73 52.99 163.55 58.09 163.55 C 61.394 163.457 64.534 162.088 66.85 159.73 L 66.85 159.76 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 78.8 165.41 L 78.8 161.41 C 81.456 162.808 84.399 163.572 87.4 163.64 C 90.85 163.64 93.78 162.16 93.78 159.64 C 93.78 157.52 92.13 156.51 89.95 155.92 L 85.23 154.6 C 80.55 153.33 78.59 150.78 78.59 146.74 C 78.59 141.96 83.26 139.15 88.73 139.15 C 91.357 139.095 93.954 139.719 96.27 140.96 L 96.27 144.96 C 93.865 143.686 91.181 143.03 88.46 143.05 C 85.76 143.05 83.05 144.16 83.05 146.82 C 83.05 148.57 84.32 149.69 86.87 150.38 L 91.07 151.6 C 96.43 152.93 98.18 155.69 98.18 159.6 C 98.18 164.75 93.46 167.46 87.08 167.46 C 84.06 167.48 80.34 166.47 78.8 165.41 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 128.4 149.9 L 128.4 166.84 L 124.1 166.84 L 124.1 149.9 C 124.1 145.33 121.34 143.05 117.57 143.05 C 114.113 143.204 110.99 145.158 109.34 148.2 L 109.34 166.84 L 104.98 166.84 L 104.98 127.01 L 109.34 127.01 L 109.34 143.53 C 111.557 140.723 114.944 139.096 118.52 139.12 C 124.31 139.12 128.4 143 128.4 149.9 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 166.79 153.3 C 166.79 161.9 161.17 167.48 153.84 167.48 C 150.752 167.513 147.775 166.332 145.55 164.19 L 145.55 166.84 L 135.73 166.84 L 135.73 127.01 L 145.55 127.01 L 145.55 142.41 C 147.772 140.265 150.751 139.082 153.84 139.12 C 161.17 139.12 166.79 144.7 166.79 153.3 Z  M 157.45 153.3 C 157.552 151.593 156.93 149.923 155.737 148.699 C 154.543 147.475 152.889 146.811 151.18 146.87 C 149.118 146.931 147.136 147.681 145.55 149 L 145.55 157.6 C 147.136 158.919 149.118 159.669 151.18 159.73 C 152.889 159.789 154.543 159.125 155.737 157.901 C 156.93 156.677 157.552 155.007 157.45 153.3 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 189.42 139.76 L 199.24 139.76 L 199.24 166.84 L 189.42 166.84 L 189.42 163.84 C 187.284 166.158 184.282 167.487 181.13 167.51 C 175.66 167.51 171.13 164.22 171.13 156.94 L 171.13 139.76 L 180.9 139.76 L 180.9 154.76 C 180.9 157.62 182.44 159.27 184.83 159.27 C 186.562 159.192 188.199 158.451 189.4 157.2 L 189.42 139.76 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 233.39 149.69 L 233.39 166.84 L 223.64 166.84 L 223.64 151.84 C 223.64 148.97 222.04 147.33 219.71 147.33 C 217.956 147.391 216.295 148.134 215.08 149.4 L 215.08 166.87 L 205.31 166.87 L 205.31 139.76 L 215.08 139.76 L 215.08 142.84 C 217.225 140.498 220.245 139.151 223.42 139.12 C 228.87 139.12 233.39 142.41 233.39 149.69 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 267.27 149.69 L 267.27 166.84 L 257.5 166.84 L 257.5 151.84 C 257.5 148.97 255.9 147.33 253.57 147.33 C 251.816 147.391 250.155 148.134 248.94 149.4 L 248.94 166.87 L 239.17 166.87 L 239.17 139.76 L 248.94 139.76 L 248.94 142.84 C 251.085 140.498 254.105 139.151 257.28 139.12 C 262.75 139.12 267.27 142.41 267.27 149.69 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <path d=" M 292.23 139.76 L 301.89 139.76 L 301.89 140.34 L 291.32 167.11 C 289.25 172.42 284.68 177.83 276.51 177.83 C 274.549 177.807 272.593 177.63 270.66 177.3 L 270.66 169.92 C 272.093 170.236 273.553 170.414 275.02 170.45 C 277.447 170.425 279.717 169.244 281.13 167.27 L 269.13 140.27 L 269.13 139.74 L 278.91 139.74 L 286.07 157.05 L 292.23 139.76 Z "
                       fill="currentColor" className="theme-on-surface"/>
                <linearGradient id="_lgradient_9" x1="-5.329070518200751e-15" y1="0.4998687778846913" x2="0.9999999999999967"
                                y2="0.4998687778846913" gradientTransform="matrix(161.68,0,0,114.31,70.1,0)"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopOpacity="1" stopColor="rgb(116,200,225)"/>
                    <stop offset="100%" stopOpacity="1" stopColor="rgb(216,9,126)"/>
                </linearGradient>
                <path d=" M 169.98 106.98 L 213.98 106.98 L 213.98 114.31 L 70.1 114.31 L 70.1 106.98 L 113.52 106.98 C 113.479 106.648 113.459 106.314 113.46 105.98 C 113.355 102.944 114.914 100.093 117.527 98.544 C 120.14 96.994 123.39 96.994 126.003 98.544 C 128.616 100.093 130.175 102.944 130.07 105.98 C 130.071 106.314 130.051 106.648 130.01 106.98 L 153.49 106.98 C 153.449 106.648 153.429 106.314 153.43 105.98 C 153.325 102.944 154.884 100.093 157.497 98.544 C 160.11 96.994 163.36 96.994 165.973 98.544 C 168.586 100.093 170.145 102.944 170.04 105.98 C 170.041 106.314 170.021 106.648 169.98 106.98 L 169.98 106.98 Z  M 89.64 60.72 C 80.961 49.324 76.279 35.385 76.32 21.06 C 76.311 15.163 77.091 9.29 78.64 3.6 L 79.64 0 L 83.22 1.06 C 96.646 5.082 108.449 13.262 116.93 24.42 C 125.606 35.817 130.285 49.756 130.24 64.08 C 130.244 69.996 129.454 75.885 127.89 81.59 L 126.89 85.19 L 123.32 84.13 C 109.898 80.088 98.106 71.892 89.64 60.72 L 89.64 60.72 Z  M 83.64 21.06 C 83.601 33.788 87.759 46.174 95.47 56.3 C 102.207 65.164 111.319 71.936 121.75 75.83 C 122.543 71.947 122.942 67.993 122.94 64.03 C 122.979 51.302 118.821 38.916 111.11 28.79 C 104.371 19.928 95.259 13.156 84.83 9.26 C 84.037 13.143 83.638 17.097 83.64 21.06 Z  M 231.78 53.33 L 228.25 54.6 C 218.462 58.081 207.849 58.513 197.81 55.84 C 189.571 69.081 176.882 78.952 162.02 83.68 L 159.02 84.68 L 157.64 81.82 C 154.916 76.34 153.505 70.3 153.52 64.18 C 153.524 45.017 167.183 28.577 186.021 25.063 C 204.859 21.548 223.527 31.957 230.44 49.83 L 231.78 53.33 Z  M 190.69 53.33 C 189.62 52.86 188.56 52.33 187.53 51.83 C 181.652 48.782 176.417 44.629 172.11 39.6 C 161.816 48.495 158.143 62.855 162.9 75.6 C 174.257 71.273 183.974 63.499 190.69 53.37 L 190.69 53.33 Z "
                      fill="url(#_lgradient_9)"/>
            </g>
        </svg>
    );
};

export default LogotypeVerticalIcon;
