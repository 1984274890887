import NavItem from '../../../components/nav-section/NavItem';
import NavbarMoreMenu from './NavbarMoreMenu';
import CreateContentButton from '../../../components/shared/components/CreateContentButton';
import * as React from 'react';
import useAuth from '../../../hooks/useAuth';
import NavMessagesItem from "../../../components/nav-section/NavMessagesItem";

import NavNotificationsItem from "../../../components/nav-section/NavNotificationsItem";
import {List} from "konsta/react";

interface Props {
    collapsed: boolean,
    navConfig: any[]
}

const NavList = ({collapsed, navConfig}: Props) => {
    const {user} = useAuth();
    const {isAuthenticated} = useAuth();

    return (
        <List nested className="cursor-pointer">
            {navConfig.map((navItem) => (
                <>
                    {navItem.path === '/notifications' ? (
                        <NavNotificationsItem key={navItem.title} collapsed={collapsed} item={navItem}/>
                    ) : navItem.path === '/direct/inbox' ? (
                        <NavMessagesItem key={navItem.title} collapsed={collapsed} item={navItem}/>
                    ) : navItem.path === '/campaign/creators' ? (
                        <>
                            {user?.contentCreator ? (
                                <NavItem key={navItem.title} collapsed={collapsed} item={navItem}/>
                            ) : null}
                        </>
                    ) : (
                        <NavItem key={navItem.title} collapsed={collapsed} item={navItem}/>
                    )}
                </>
            ))}

            <NavbarMoreMenu collapsed={collapsed}/>

            {isAuthenticated ? <CreateContentButton collapsed={collapsed}/> : null}
        </List>
    )
}

export default NavList;
