import React, {ReactElement, useLayoutEffect, useState} from "react";
import {ReactComponent as HeartSolidIcon} from '../../../assets/icons/heart-solid.svg';
import {ReactComponent as CommentDotsSolidIcon} from '../../../assets/icons/comment-dots-solid.svg';
import {ReactComponent as AtSolidIcon} from '../../../assets/icons/at-solid.svg';
import {ReactComponent as UserSolidIcon} from '../../../assets/icons/user-solid.svg';
import {ReactComponent as CircleDollarSolidIcon} from '../../../assets/icons/circle-dollar-solid.svg';
import {alpha, styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import {useLocation} from "react-router-dom";

interface Props {
    children: ReactElement
    summary: any;
    placement?: 'right' | 'top';
}

const NotificationsSummaryBubble = ({children, summary, placement}: Props) => {
    const {pathname} = useLocation();
    const [open, setOpen] = useState(false);

    useLayoutEffect(() => {
        if (pathname !== '/notifications' && !!summary) {
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 10000);
        }
    }, [summary])

    if (!summary) {
        return children;
    }

    return (
        <HtmlTooltip
            placement={placement}
            open={open}
            title={
                <div className="flex gap-x-3 items-center text-white text-base px-2 py-1">
                    {summary.likes ? (
                        <div className="flex gap-x-1 items-center">
                            <HeartSolidIcon className="h-4"/>
                            {summary.likes}
                        </div>
                    ) : null}

                    {summary.comments ? (
                        <div className="flex gap-x-1 items-center">
                            <CommentDotsSolidIcon className="h-4"/>
                            {summary.comments}
                        </div>
                    ) : null}

                    {summary.mentions ? (
                        <div className="flex gap-x-1 items-center">
                            <AtSolidIcon className="h-4"/>
                            {summary.mentions}
                        </div>
                    ) : null}

                    {summary.follows ? (
                        <div className="flex gap-x-1 items-center">
                            <UserSolidIcon className="h-4"/>
                            {summary.follows}
                        </div>
                    ) : null}

                    {summary.sales ? (
                        <div className="flex gap-x-1 items-center">
                            <CircleDollarSolidIcon className="h-4"/>
                            {summary.sales}
                        </div>
                    ) : null}
                </div>
            }
        >
            {children}
        </HtmlTooltip>
    )
}

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.error.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        borderRadius: '12px',
        boxShadow: `0 0 8px ${alpha(theme.palette.common.black, 0.5)}`,
    },
}));

export default NotificationsSummaryBubble;