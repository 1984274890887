import {useLocation, useNavigate} from 'react-router-dom';

const useBackRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const back = () => {
        try {
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(-1);
            }
        } catch (e) {
            navigate('/');
        }
    }

    return {
        back
    };
}

export default useBackRoute;