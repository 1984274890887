import { ImageRequestParamsResize } from '../types/ImageRequestParamsResize';
import { ImageRequestParams } from '../types/ImageRequestParams';
import { ImageRequest } from '../types/ImageRequest';

export const getImageUrl = (bucket: string, key: string, width?: number, height?: number): string => {
    const paramsResize = {} as ImageRequestParamsResize
    paramsResize.width = width
    paramsResize.height = height

    const editsParams = {} as ImageRequestParams
    editsParams.resize = paramsResize

    const imageRequest = {} as ImageRequest
    imageRequest.bucket = bucket
    imageRequest.key = key
    imageRequest.edits = editsParams

    const requestJson = JSON.stringify(imageRequest)

    const base64 = btoa(requestJson)

    return `https://d2ylgn1nlrkc73.cloudfront.net/${base64}`
}
