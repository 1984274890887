import * as React from 'react';
import {memo} from 'react';
import {classNames} from '../../utils/utils';

type Props = {
    curPage: number;
    maxPage: number;
}

const PaginatorDot = (props: React.ComponentProps<'div'> & Props) => {
    return (
        <div className={props.className}>
            <div className="flex justify-center items-center gap-1">
                {[...Array(props.maxPage)].map((value, index) => (
                    <div key={index} className={classNames(props.curPage === index ? 'w-4 h-4 bg-primary-300' : 'w-2 h-2 bg-primary-50', 'rounded-full')} />
                ))}
            </div>
        </div>
    );
}

export default memo(PaginatorDot);
