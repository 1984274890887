import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserStory} from "../../models/UserStory";

export interface CreateStoryState {
    userStories: UserStory[];
    playingTime: number
    timelineWidth: number
}

const initialState: CreateStoryState = {
    userStories: [],
    playingTime: 0,
    timelineWidth: 0
}

export const createStorySlice = createSlice({
    name: 'createStory',
    initialState,
    reducers: {
        clearState: state => state = initialState,
        initList: (state, action: PayloadAction<UserStory[]>) => {
            const userStories = (action.payload || [])

            const updatedUserStories = userStories.map((storyGroup) => {
                if (storyGroup.stories.every((s) => s.viewed)) {
                    return { ...storyGroup, viewed: true };
                }
                return storyGroup;
            });

            state.userStories = updatedUserStories
        },
        changeStoryViewed: (state, action: PayloadAction<{userStoryIndex: number, storyIndex: number}>) => {
            const payload = action.payload
            const userStories = [...state.userStories]

            userStories[payload.userStoryIndex].stories[payload.storyIndex].viewed = true

            if (userStories[payload.userStoryIndex].stories.every(s => s.viewed)) {
                userStories[payload.userStoryIndex].viewed = true
            }

            state.userStories = userStories
        },
        updatePlayingTime: (state, action: PayloadAction<number>) => {
            state.playingTime = action.payload
        },
        updateTimelineWidth: (state, action: PayloadAction<number>) => {
            state.timelineWidth = action.payload
        }
    }
})

export const {clearState, initList, changeStoryViewed, updatePlayingTime, updateTimelineWidth} = createStorySlice.actions

export default createStorySlice.reducer