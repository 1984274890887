import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface FeedState {
    value: number;
    gender?: 'women' | 'man' | 'trans';
    feedType: 'following' | 'for_you';
    category?: string;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: FeedState = {
    value: 0,
    feedType: 'following',
    status: 'idle',
};

export const feedSlice = createSlice({
    name: 'feed',
    initialState,
    reducers: {
        clearFilters: state => {
            state.gender = initialState.gender;
            state.feedType = initialState.feedType;
        },
        changeGender: (state, action: PayloadAction<'women' | 'man' | 'trans' | undefined>) => {
            state.gender = action.payload;
            state.feedType = 'for_you';
        },
        changeCategory: (state, action: PayloadAction<'following' | 'for_you'>) => {
            state.feedType = action.payload;
        },
        changeFeedType: (state, action: PayloadAction<'following' | 'for_you'>) => {
            state.feedType = action.payload;
            if (state.feedType === 'following') {
                state.gender = initialState.gender
            }
        },
    }
})

export const { clearFilters, changeGender, changeCategory, changeFeedType } = feedSlice.actions

export default feedSlice.reducer