import * as React from "react";
import {useTranslation} from "react-i18next";


import {ListItem} from "konsta/react";
import {useModal} from "../../../shared/modal-provider";

import {ReactComponent as CircleEllipsisIcon} from '../../../assets/icons/regular/circle-ellipsis.svg';
import {NavbarMoreMenuPopover} from "./NavbarMoreMenuPopover";

interface Props {
    collapsed: boolean
}

const NavbarMoreMenu = ({collapsed}: Props) => {
    const {t} = useTranslation()
    const {showModal, hideModal} = useModal()

    const openPopover = (targetRef) => {
        const modal = showModal(NavbarMoreMenuPopover, {
            target: targetRef,
            onClose: () => {
                hideModal(modal.id)
            }
        })
    }

    return (
        <>
            <ListItem
                id="navbar-more-popover"
                dividers={false}
                title={!collapsed && t('more_options')}
                media={<CircleEllipsisIcon className="theme-on-surface h-6 w-6"/>}
                onClick={() => openPopover("#navbar-more-popover")}
            />
        </>
    )
}

export default NavbarMoreMenu;
