import * as React from 'react';

const CirclePlusLightIcon = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={props.className}
            fill="currentColor"
        >
            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256S512 397.4 512 256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM352 240h-80V160c0-8.844-7.156-16-16-16S240 151.2 240 160v80H160C151.2 240 144 247.2 144 256S151.2 272 160 272h80V352c0 8.844 7.156 16 16 16s16-7.156 16-16V272H352c8.844 0 16-7.156 16-16S360.8 240 352 240z"/>
        </svg>
    );
};

export default CirclePlusLightIcon;
