import {useTranslation} from 'react-i18next';
import React, {memo, useContext} from 'react';
import CreatorProfileContext from '../../context/CreatorProfileConfigContext';
import {ArrowRightIcon} from "@heroicons/react/outline";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useBackRoute from "../../hooks/useBackRoute";
import {Button, Link, Navbar, NavbarBackLink} from "konsta/react";

const faqs = [
    {
        question: "Que tipo de conteúdo posso postar?",
        answer:
            "Você pode postar de tudo!!! Conteúdo Gamer, Fitness, Sensual, Música, relacionados a fotografia em geral e até conteúdos adultos.",
    },
    {
        question: "É permitido nudez?",
        answer:
            "Sim, é permitido nudez. Desde que, todos os participantes da foto ou vídeo sejam maiores de 18 anos.",
    },
    {
        question: "Como ganho dinheiro?",
        answer:
            "Você cria e publica conteúdos de forma pública ou privada. Os conteúdos privados são exclusivos para os membros que pagam mensalmente para ver seu conteúdo. Você também ganha com os presentes que os usuários enviam.",
    },
    {
        question: "Preciso mostrar o rosto ou expor informações pessoais?",
        answer:
            "Não, na Stash Bunny você cria um perfil artístico. Isso quer dizer que você é quem define como os usuários a (o) conhecerão, você escolhe um nome público e uma foto de perfil, que não precisa conter seu rosto se não quiser.",
    },
    {
        question: "Pago para usar a plataforma?",
        answer:
            "Não é necessário pagar nada! Quando você ganha dinheiro na plataforma você fica com 80% de tudo, incluindo assinaturas mensais, packs, mensagens e presentes. A plataforma fica com 20% para continuarmos investindo em experiência, segurança e ainda mais melhorias pra você.",
    },
    {
        question: "Como consigo membros?",
        answer:
            "Há várias formas de conseguir membros. 1 - Mantenha seu perfil sempre ativo com posts públicos e privados para conseguir membros de forma orgânica (aumentar a sua base de fãs de forma simples e rápida); 2 - Você também pode divulgar seu perfil da Stash Bunny nas suas redes sociais como Instagram ou X e trazer seus fãs para ver seus melhores conteúdos;",
    },
]

const CreatorAccountWelcomeFaq = () => {
    const {t} = useTranslation();
    const {back} = useBackRoute();
    const {state, setState} = useContext(CreatorProfileContext);

    const goToNext = () => setState({...state, step: 'categories'})

    return (
        <>
            <Navbar
                left={
                    <NavbarBackLink showText={false} onClick={back} className="theme-on-surface ml-2"/>
                }
                right={
                    <Link navbar onClick={goToNext} className="mr-2 uppercase font-semibold text-sm">{t('continue')}</Link>
                }
            />

            <div className="p-4 theme-on-surface">


                <div className="w-full sm:max-w-md mx-auto px-6 lg:px-8">

                    <h2 className="text-center text-3xl font-bold sm:text-4xl">
                        Alguma dúvida antes de começar?
                    </h2>
                    <div className="mt-6">
                        {faqs.map((faq) => (
                            <Accordion key={faq.question}
                                       className="theme-surface theme-on-surface"
                                       elevation={0} disableGutters
                            sx={{
                                '&:before': { backgroundColor: 'transparent' }
                            }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header" className="border-none px-0"
                                >
                                    <span className="font-medium text-lg">{faq.question}</span>
                                </AccordionSummary>
                                <AccordionDetails className="border-none p-0">
                                            <span className="text-base text-gray-600 dark:text-neutral-500">
                                                {faq.answer}
                                            </span>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>

                </div>
            </div>
        </>
    )
}

export default memo(CreatorAccountWelcomeFaq);
