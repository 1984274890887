import CreatorAccountBankData from '../professional-account-config/CreatorAccountBankData';
import Dialog from "@mui/material/Dialog";
import useResponsive from '../../hooks/useResponsive';


const CreatorAccountBankDataDialog = () => {
    const isMobile = useResponsive('down', 'sm');

    return (
        <Dialog
            open={true}
            scroll="paper"
            fullScreen={isMobile}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <CreatorAccountBankData dialogMode={true}/>
        </Dialog>
    )
}

export default CreatorAccountBankDataDialog;
