import axios from 'axios';
import { auth } from '../firebase';
import {getI18n} from "react-i18next";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

api.interceptors.request.use(
    async (request) => {
        const headers: any = {
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json; charset=UTF-8',
            'Accept-Language': getI18n().language
        };
        if (isAuthenticatedURL(request.method, request.url)) {
            const currentToken = await auth.currentUser?.getIdToken();
            if (currentToken) {
                headers['Authorization'] = `Bearer ${currentToken}`;
            }
        }
        request.headers = headers;

        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function isAuthenticatedURL(
    method: string | undefined,
    url: string | undefined
): boolean {
    if (method === 'get' && url?.startsWith('auth/users/username/available/')) {
        return false;
    }
    return true;
}

export default api;
