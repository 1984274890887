import {lazy} from "react";

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = componentImport => lazy(async () => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    return componentImport().then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        return component;
    }).catch((error) => {
        if (!hasRefreshed) { // not been refreshed yet
            window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
            console.log('DEI RELOAD MANO')
            return window.location.reload(); // refresh the page
        }
        throw error; // Default error behaviour as already tried refresh
    });
});

export default lazyRetry;