import {Button, Preloader} from "konsta/react";
import {ComponentProps, forwardRef} from "react";

interface ButtonProps extends ComponentProps<typeof Button> {
    loading?: boolean
}

export const KonstaLoadingButton = forwardRef<typeof Button, ButtonProps>((props, ref) => {
    const {children, disabled, loading, ...restProps} = props

    return (
        <Button {...restProps} disabled={disabled || loading}>
            {loading ? (
                <>
                    <Preloader size="w-5 h-5" className="!theme-on-surface" />
                </>
            ) : children}
        </Button>
    )
})