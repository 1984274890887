import api from '../apis';
import {ResponseData} from '../types/ResponseData';

class NotificationService {
    getHistories = ({pageParam = 0}, types?: string): Promise<ResponseData> =>
        api
            .get(`notification/histories?page=${pageParam}&size=20&t=${types}`)
            .then((response) => response.data)

    saveNotificationToken = (request: { token: string }): Promise<void> =>
        api
            .post('notification/preferences/token', request)
            .then((response) => response.data);

    getSummary = (): Promise<any> =>
        api
            .get(`notification/summary`)
            .then((response) => response.data);

    saveUserActivity = (): Promise<void> =>
        api
            .post('notification/activity/read')
            .then((response) => response.data);
}

export const notificationService = new NotificationService();
