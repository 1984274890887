import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MediaType} from "../../models/MediaType";

export interface MediaParams {
    key: string;
    type: MediaType;
    contentType: string;
    file: File;
    status: string;
    progress: number;
    track: any[];
    duration?: number;
    height?: number;
    width?: number;
}

export type IdentityDocumentSide = 'front' | 'back'

interface IdentityDocuments {
    front?: MediaParams
    back?: MediaParams
}

interface IdentityData {
    fullName: string;
    birthDate: Date;
}

export interface IdentityVerificationState {
    document: IdentityDocuments;
    selfie?: MediaParams
    data: IdentityData;
    step: 'personal_data' | 'documents' | 'selfie' | 'uploading' | 'in_progress';
}

const initialState: IdentityVerificationState = {
    document: {} as IdentityDocuments,
    data: {} as IdentityData,
    step: 'personal_data',
}

export const identityVerificationSlice = createSlice({
    name: 'identityVerification',
    initialState,
    reducers: {
        clearState: state => state = initialState,
        addIdentityData: (state, action: PayloadAction<{fullName: string, birthDate: Date}>) => {
            const data = {} as IdentityData;
            data.fullName = action.payload.fullName
            data.birthDate = action.payload.birthDate

            state.data = data;
        },
        addDocument: (state, action: PayloadAction<{file: MediaParams, side: IdentityDocumentSide}>) => {
            if (action.payload.side === 'front') {
                state.document.front = action.payload.file;
            } else if (action.payload.side === 'back') {
                state.document.back = action.payload.file;
            }
        },
        removeDocument: (state, action: PayloadAction<IdentityDocumentSide>) => {
            if (action.payload === 'front') {
                state.document.front = initialState.document.front;
            } else if (action.payload === 'back') {
                state.document.back = initialState.document.back;
            }
        },
        addSelfieDocument: (state, action: PayloadAction<MediaParams>) => {
            state.selfie = action.payload;
        },
        removeSelfieDocument: (state) => {
            state.selfie = initialState.selfie;
        },
        changeStep: (state, action: PayloadAction<'personal_data' | 'documents' | 'selfie' | 'uploading' | 'in_progress'>) => {
            state.step = action.payload;
        },
    }
})

export const { clearState, addIdentityData, addDocument, removeDocument, addSelfieDocument, removeSelfieDocument, changeStep } = identityVerificationSlice.actions

export default identityVerificationSlice.reducer