import * as React from 'react';

const LogotypeHorizontalIcon = (props: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 574.06 102.06"  className={props.className}>
            <defs>
                <linearGradient id="linear-gradient" x1="-21225.3" y1="16233.64" x2="-21063.62" y2="16233.64"
                                gradientTransform="matrix(0.89, 0, 0, -0.89, 18889.46, 14498.97)"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#74c8e1"/>
                    <stop offset="1" stopColor="#d8097e"/>
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Camada_1" data-name="Camada 1">
                    <path fill="url(#linear-gradient)"
                          d="M89.13,95.52h39.26v6.54H0V95.52H38.75a6.54,6.54,0,0,1-.06-.89,7.42,7.42,0,0,1,14.83,0,6.54,6.54,0,0,1-.06.89h21a6.54,6.54,0,0,1-.06-.89,7.42,7.42,0,0,1,14.83,0A6.55,6.55,0,0,1,89.13,95.52ZM17.43,54.24A58.1,58.1,0,0,1,5.54,18.84,59,59,0,0,1,7.65,3.21L8.53,0l3.2,1A59.13,59.13,0,0,1,41.81,21.84a58.17,58.17,0,0,1,11.88,35.4,59.36,59.36,0,0,1-2.1,15.63l-.89,3.21-3.19-1A59.13,59.13,0,0,1,17.43,54.24Zm-5.35-35.4A51.63,51.63,0,0,0,22.64,50.29,52.55,52.55,0,0,0,46.09,67.72a52.4,52.4,0,0,0,1.07-10.53A51.63,51.63,0,0,0,36.6,25.74,52.63,52.63,0,0,0,13.14,8.31,53.2,53.2,0,0,0,12.08,18.84Zm132.21,28.8-3.15,1.13A45.73,45.73,0,0,1,114,49.88,58.51,58.51,0,0,1,82,74.73l-2.67.85-1.25-2.52a35.52,35.52,0,1,1,63.93-31c.38.8.74,1.62,1.07,2.45Zm-36.67,0c-1-.41-1.9-.86-2.82-1.34A45.94,45.94,0,0,1,91,35.36,29,29,0,0,0,82.82,67.5a51.93,51.93,0,0,0,24.8-19.83Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M170.35,84.62V79.27a25.88,25.88,0,0,0,11.51,3c4.62,0,8.52-2,8.52-5.35,0-2.85-2.19-4.2-5.11-5l-6.33-1.85c-6.24-1.7-8.88-5.11-8.88-10.52,0-6.39,6.26-10.15,13.58-10.15a20.62,20.62,0,0,1,10.09,2.41v5.35a22.13,22.13,0,0,0-10.45-2.56c-3.63,0-7.25,1.49-7.25,5,0,2.34,1.71,3.84,5.12,4.76l5.61,1.63c7.18,1.78,9.52,5.47,9.52,10.71,0,6.89-6.32,10.51-14.85,10.51C177.38,87.39,172.41,86,170.35,84.62Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M224.77,81.34v5.2a23.75,23.75,0,0,1-6.38.85c-9.37,0-13.58-6.61-13.58-15.34V44.26l5-4.19h.86V50.3h13.29v5H210.64V71.9c0,5.26,2.06,10.23,8.59,10.23A21.67,21.67,0,0,0,224.77,81.34Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M259.52,50.3h5.83V86.54h-5.83V82.78A18.82,18.82,0,0,1,247,87.39c-9.74,0-18.05-7.32-18.05-19s8.31-19,18.05-19a18.75,18.75,0,0,1,12.5,4.61Zm0,26.76V59.82a16.71,16.71,0,0,0-11.72-5.11c-6.83,0-12.73,5.11-12.86,13.71.13,8.59,6,13.71,12.86,13.71A17.12,17.12,0,0,0,259.52,77Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M275.51,84.62V79.27a25.93,25.93,0,0,0,11.5,3c4.63,0,8.54-2,8.54-5.35,0-2.85-2.21-4.2-5.13-5l-6.31-1.85c-6.26-1.7-8.89-5.11-8.89-10.52,0-6.39,6.25-10.15,13.57-10.15a20.51,20.51,0,0,1,10.09,2.41v5.35a21.94,21.94,0,0,0-10.44-2.56c-3.62,0-7.25,1.49-7.25,5,0,2.34,1.7,3.84,5.11,4.76l5.62,1.63c7.17,1.78,9.52,5.47,9.52,10.71,0,6.89-6.32,10.51-14.84,10.51C282.54,87.39,277.57,86,275.51,84.62Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M341.87,63.87V86.54h-5.75V63.87c0-6.11-3.7-9.16-8.74-9.16a13.16,13.16,0,0,0-11,6.89V86.54h-5.89V33.24h5.83V55.36a15.55,15.55,0,0,1,12.29-5.9C336.4,49.45,341.87,54.64,341.87,63.87Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M393.25,68.42c0,11.51-7.54,19-17.4,19A15.71,15.71,0,0,1,364.76,83v3.55H351.62V33.24h13.14V53.85a15.69,15.69,0,0,1,11.09-4.4C385.71,49.45,393.25,56.9,393.25,68.42Zm-12.51,0a8.12,8.12,0,0,0-7.62-8.59,6.62,6.62,0,0,0-.77,0,12.35,12.35,0,0,0-7.54,2.85v11.5A12.4,12.4,0,0,0,372.35,77a8.12,8.12,0,0,0,8.4-7.81c0-.26,0-.52,0-.78Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M423.51,50.3h13.14V86.54H423.51v-4a15.23,15.23,0,0,1-11.09,4.91C405.1,87.43,399,83,399,73.29v-23h13.13V70.37c0,3.83,2.06,6,5.25,6a9.1,9.1,0,0,0,6.12-2.77Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M482.34,63.59v23H469.27V66.47c0-3.84-2.14-6-5.26-6a9,9,0,0,0-6.18,2.77V86.54H444.75V50.3h13.08v4.12a15.3,15.3,0,0,1,11.15-5C476.29,49.45,482.34,53.85,482.34,63.59Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M527.67,63.59v23H514.6V66.47c0-3.84-2.14-6-5.26-6a9,9,0,0,0-6.18,2.77V86.54H490.08V50.3h13.07v4.12a15.29,15.29,0,0,1,11.14-5C521.62,49.45,527.67,53.85,527.67,63.59Z"/>
                    <path fill="currentColor" className="theme-on-surface"
                          d="M561.07,50.3h13v.78L559.92,86.9c-2.77,7.1-8.87,14.34-19.82,14.34a50,50,0,0,1-7.82-.71V90.66a30.87,30.87,0,0,0,5.83.71,10.19,10.19,0,0,0,8.17-4.26L530.23,51v-.71h13.06l9.59,23.16Z"/>
                </g>
            </g>
        </svg>
    );
};

export default LogotypeHorizontalIcon;
