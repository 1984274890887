import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {CircularProgress} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {isInstagramWebView} from "../../utils/InstagramWebView";
import InstagramWebViewWarnModal from "./InstagramWebViewWarnModal";
import {useModal} from "mui-modal-provider";
import {FirebaseError} from "@firebase/util";
import Bugsnag from "@bugsnag/js";
import {AxiosError} from "axios";
import SuspendedAccountModal from "../shared/components/dialog/SuspendedAccountModal";
import DeletedAccountModal from "./DeletedAccountModal";
import {signInWithPopup, TwitterAuthProvider} from "firebase/auth";
import {auth} from "../../firebase";
import {authService} from "../../services/AuthService";

import {ReactComponent as XLogoIcon} from '../../assets/icons/x-logo.svg'
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";

type SignButtonType = 'sign_up' | 'sign_in';

interface Props {
    type: SignButtonType;
    description: string
}

const TwitterSignButton = ({type, description}: Props) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const [processing, setProcessing] = useState(false);
    const {showModal, hideModal} = useModal();

    const handleSign = async () => {
        if (processing) {
            return;
        }

        if (isInstagramWebView()) {
            const modal = showModal(InstagramWebViewWarnModal, {
                type,
                provider: 'Twitter',
                onCancel: () => {
                    hideModal(modal.id);
                }
            });
            return;
        }

        try {
            setProcessing(true)

            const userCredential = await signInWithPopup(auth, new TwitterAuthProvider())

            if (userCredential.user) {
                await authService.completeLogin(userCredential.user)
            }

            redirectToReferrer()
        } catch (error: unknown) {
            if (error instanceof FirebaseError) {
                _processAuthErrorOnSignIn(error)
            } else {
                _processSystemErrorOnSignIn(error as any)
            }
        } finally {
            setProcessing(false);
        }
    }

    const _processAuthErrorOnSignIn = (error: FirebaseError) => {
        Bugsnag.notify(error);
        console.log(`An error happened. ${error}`);

        if (error.code) {
            switch(error.code) {
                case 'auth/email-already-in-use':
                    enqueueSnackbar(t('Já existe uma conta para esse e-mail.'))
                    break;
                case 'auth/popup-closed-by-user':
                    break;
                default:
                    enqueueSnackbar(t(error.message), {variant: "error"});
            }
        } else {
            enqueueSnackbar(t(error.message), {variant: "error"});
        }
    }

    const _processSystemErrorOnSignIn = ({response}: AxiosError) => {

        if (!response) {
            enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
            return
        }

        const dataError = response.data as { statusCode: number, errorCode: string, timestamp: string }
        console.log(`An error happened.`)
        console.log(dataError)

        if (dataError.errorCode) {
            switch (dataError.errorCode) {
                case 'auth/user_deleted':
                    notifyDeletedAccount()
                    break
                case 'auth/user_suspended':
                    notifySuspendedAccount()
                    break
                default:
                    enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
            }
        } else {
            enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
        }
    }

    const notifySuspendedAccount = () => {
        const modal = showModal(SuspendedAccountModal, {
            onConfirm: () => {
                hideModal(modal.id)
                navigate('https://support.stashbunny.com')
            },
            onCancel: () => {
                hideModal(modal.id);
            }
        })
    }

    const notifyDeletedAccount = () => {
        const modal = showModal(DeletedAccountModal, {
            onConfirm: () => {
                hideModal(modal.id)
                navigate('/signup')
            },
            onCancel: () => {
                hideModal(modal.id)
            }
        })
    }

    const redirectToReferrer = () => {
        const state = location.state as { from };
        if (state && state.from) {
            const from = state.from?.pathname || "/";
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, {replace: true});
        }
    }

    return (
        <KonstaLoadingButton
            onClick={handleSign}
            loading={processing}
            rounded
            outline
            large
            className="theme-surface-inverse theme-on-surface-inverse normal-case border-[1px] !border-neutral-500 dark:border-none">
            <XLogoIcon className="w-5 h-5 group-disabled:hidden" />
            &nbsp;&nbsp;&nbsp;
            {description}
        </KonstaLoadingButton>
    )
}

export default TwitterSignButton