import {Outlet} from 'react-router-dom';
import NavbarVertical from './navbar/NavbarVertical';
import useResponsive from '../../hooks/useResponsive';
import {classNames} from '../../utils/utils';
import React, {Suspense} from 'react';
import lazyRetry from '../../utils/lazyRetry';

const LeftPanelDesktop = lazyRetry(() => import("../../components/NewLeftPanelDesktop"));

interface Props {
    newMobile?: boolean
}

const MainLayout = ({newMobile}: Props) => {
    const isCollapsed = useResponsive('down', 'md');
    const isMobile = useResponsive('down', 'sm');

    if (isMobile && newMobile) {
        return <Outlet />
    } else if (isMobile) {
        return <div className="fixed inset-0 overflow-y-auto theme-surface">
            <Outlet />
        </div>
    }

    return (
        <div className="fixed inset-0 overflow-y-auto w-full min-h-screen flex flex-grow justify-center theme-surface">

            <div className={classNames(!!isCollapsed ? 'w-16' : 'w-60', 'flex-shrink-0 relative')}>
                <div className="h-full relative">
                    <div className={classNames(!!isCollapsed ? 'w-16' : 'w-60', 'fixed top-0 w-16 h-full')}>
                        <NavbarVertical collapsed={!!isCollapsed} />
                    </div>
                </div>
            </div>

            <main className="min-w-0 w-[600px] relative">
                <Outlet />
            </main>

            <aside className="relative hidden w-80 flex-shrink-0 overflow-y-auto hairline-l xl:flex xl:flex-col">

                <div className="absolute inset-0 left-6">
                    <Suspense>
                        <LeftPanelDesktop/>
                    </Suspense>
                </div>

            </aside>

        </div>
    );
}

export default MainLayout;
