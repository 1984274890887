import * as React from 'react';

const RabbitLightIcon = ({className}: React.ComponentProps<'svg'>): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512"
             className={className}
             fill="currentColor">
            <path d="M505.9 450.8c-.6251-1-1.25-2-2-2.875L389.5 304h1.875c48.88 0 88.63-39.75 88.63-88.62c0-28.62-13.88-55.5-37.13-72.13c-.6251-.5-1.25-.875-1.875-1.25l-24.63-15.13l.75-3C431 72 431.1 12.5 389.5 1.375C386 .5 382.5 0 378.9 0c-17.63 0-31.75 10.38-42.87 24.5C324.9 10.38 310.8 0 293.2 0c-3.625 0-7.184 .5-10.68 1.375C240.9 12.5 241 72 254.9 123.9c2.25 8.625 5 17 8.125 25.12c-2.625 6.5-4.625 13.5-5.75 20.62C256.4 174.8 256 179.9 256 185v41.62c-67.38 11.38-122.8 58-146.9 120C98 339.8 85.38 336 72 336c-19.25 0-37.38 7.5-51 21.12c-28 28-28 73.75 0 101.8C34.63 472.5 52.75 480 72 480c10.25 0 20-2.875 29.13-7C110.9 495.9 133.6 512 160 512h160c17.63 0 32.01-14.38 32.01-32c0-23.25-12.5-43.63-31-54.88l15.75-9.875l65.13 80.25C409.4 505.9 421.3 512 434.3 512h37.75c14.5 0 28-7.875 35-20.62C514 478.6 513.6 463.1 505.9 450.8zM96 416v23C88.88 444.6 80.5 448 72 448c-10.25 0-20.5-3.875-28.38-11.75c-15.63-15.62-15.63-40.87 0-56.5C51.5 371.9 61.75 368 72 368c9.1 0 19.88 3.875 27.63 11.38C97.38 391.2 96 403.5 96 416zM472 480h-37.75c-2.75 0-5.25-1.375-6.75-3.75l-83.75-103.1L224 448l64 .0023c17.63 0 32 14.37 32 31.1L160 480c-17.75 0-32-14.38-32-32l-.0018-32c0-88.38 71.63-159.1 160-159.1l.0024-70.1c0-3.5 .2516-6.858 .8767-10.23c1.5-9.25 4.872-17.53 9.497-24.28c-4.75-10.25-9.125-21.88-12.63-34.88c-11.25-42.25-9-79.5 5-83.38C291.5 32.12 292.4 32 293.1 32c13.63 0 32.13 28.6 42.88 67.22C346.9 60.6 365.3 32 378.9 32c.75 0 1.625 .125 2.375 .25c14 3.875 16.25 41.13 5 83.38c-2.5 9-5.375 17.38-8.5 25.13l46.52 28.51c14.88 10.63 23.74 27.75 23.74 46.12c0 31.25-25.39 56.62-56.64 56.62L352 272v36.38l126.8 159.4C482.1 473.1 478.3 480 472 480zM367.1 191.1c0-8.875-7.125-16-16-16s-16 7.125-16 16s7.126 16 16 16S367.1 200.9 367.1 191.1z"/>
        </svg>
    );
};

export default RabbitLightIcon;
