import React from "react";
import {Link as RouterLink} from 'react-router-dom';

type Props = {
    disabledLink?: boolean
}

export default function Logo({className, disabledLink}: React.ComponentProps<'svg'> & Props) {

    const _buildLogo= () => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="100%" height="100%"
             className={className}>
            <defs>
                <clipPath id="A">
                    <path d="M0 0h16v16H0z"/>
                </clipPath>
            </defs>
            <g clipPath="url(#A)">
                <linearGradient id="B" x1="0" y1="7.998" x2="16.003" y2="7.998" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stop-color="#74c8e1"/>
                    <stop offset="100%" stop-color="#d8097e"/>
                </linearGradient>
                <path
                    d="M9.886 12.932h4.355v.725H0v-.725h4.298a.82.82 0 0 1-.006-.099.82.82 0 0 1 1.241-.736.82.82 0 0 1 .403.736.82.82 0 0 1-.006.099h2.324a.82.82 0 0 1-.006-.099c-.01-.301.144-.583.402-.736a.82.82 0 0 1 1.242.736.82.82 0 0 1-.006.099h0zM1.933 8.353A6.45 6.45 0 0 1 .615 4.427a6.55 6.55 0 0 1 .233-1.728l.099-.356.354.105A6.55 6.55 0 0 1 4.637 4.76a6.45 6.45 0 0 1 1.318 3.925c0 .586-.078 1.169-.233 1.734l-.099.356-.353-.105c-1.33-.399-2.498-1.211-3.337-2.317h0zm-.594-3.926A5.73 5.73 0 0 0 2.51 7.915c.667.878 1.57 1.548 2.602 1.933.079-.384.118-.775.118-1.168a5.73 5.73 0 0 0-1.171-3.488c-.667-.877-1.569-1.547-2.601-1.933a5.88 5.88 0 0 0-.118 1.168h-.001zM16 7.621l-.346.126a5.09 5.09 0 0 1-3.013.123c-.816 1.31-2.072 2.287-3.543 2.755l-.297.099-.137-.279a3.89 3.89 0 0 1-.408-1.746 3.94 3.94 0 0 1 7.613-1.42l.131.342zm-4.067 0l-.313-.148c-.581-.302-1.098-.713-1.523-1.211a3.22 3.22 0 0 0-.912 3.564c1.124-.429 2.086-1.198 2.751-2.201l-.003-.004z"
                    fill="url(#B)"/>
            </g>
        </svg>
    )

    if (disabledLink) {
        return <>{_buildLogo()}</>
    }

    return <RouterLink to="/">{_buildLogo()}</RouterLink>
}
