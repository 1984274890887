import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Comment} from "../../models/CommentsSummary";

export interface CommentState {
    content: string;
    replyTo?: Comment;
}

const initialState: CommentState = {
    content: ''
}

export const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        clearState: state => {
            state.content = initialState.content;
            state.replyTo = initialState.replyTo;
        },
        setReplyTo: (state, action: PayloadAction<Comment>) => {
            state.content = initialState.content;
            state.replyTo = action.payload;
        },
        changeContent: (state, action: PayloadAction<string>) => {
            const CHARACTER_LIMIT = 150;
            state.content = action.payload.slice(0, CHARACTER_LIMIT);
        },
        addEmoji: (state, action: PayloadAction<string>) => {
            const CHARACTER_LIMIT = 150;

            const emoji = action.payload;
            const content = state.content;

            if (!content || content.length < CHARACTER_LIMIT) {
                state.content = content ? `${content}${emoji}` : emoji;
            }
        },
        deleteLastCharacter: state => {
            state.content = state.content.replace(/[^\w\s]$|.$/u, '');

            if (state.replyTo && !state.content) {
                clearState();
            }
        },
    }
})

export const { clearState, setReplyTo, changeContent, deleteLastCharacter, addEmoji } = commentSlice.actions

export default commentSlice.reducer