import React, {useState} from "react";
import useAuth from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {isInstagramWebView} from "../../utils/InstagramWebView";
import {useModal} from "mui-modal-provider";
import InstagramWebViewWarnModal from "./InstagramWebViewWarnModal";
import useLogin from "../../hooks/useLogin";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../../firebase";
import {authService} from "../../services/AuthService";
import {FirebaseError} from "@firebase/util";
import Bugsnag from "@bugsnag/js";
import {AxiosError} from "axios";
import SuspendedAccountModal from "../shared/components/dialog/SuspendedAccountModal";
import DeletedAccountModal from "./DeletedAccountModal";
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";

type SignButtonType = 'sign_up' | 'sign_in';

interface Props {
    type: SignButtonType;
    description: string
}

const GoogleSignButton = ({type, description}: Props) => {
    const {t} = useTranslation();
    const {signInWithGoogle} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const [processing, setProcessing] = useState(false);
    const {showModal, hideModal} = useModal()
    const {showLoginForm} = useLogin();

    const handleSign = async () => {
        if (processing) {
            return;
        }

        if (isInstagramWebView()) {
            const modal = showModal(InstagramWebViewWarnModal, {
                type,
                provider: 'Google',
                onCancel: () => {
                    showLoginForm();
                    hideModal(modal.id);
                }
            });
            return;
        }

        try {
            setProcessing(true)

            const userCredential = await signInWithPopup(auth, new GoogleAuthProvider())

            if (userCredential.user) {
                await authService.completeLogin(userCredential.user)
            }

            redirectToReferrer()
        } catch (error: unknown) {
            if (error instanceof FirebaseError) {
                _processAuthErrorOnSignIn(error)
            } else {
                _processSystemErrorOnSignIn(error as any)
            }
        } finally {
            setProcessing(false);
        }
    }

    const _processAuthErrorOnSignIn = (error: FirebaseError) => {
        Bugsnag.notify(error);
        console.log(`An error happened. ${error}`);

        if (error.code) {
            switch (error.code) {
                case 'auth/email-already-in-use':
                    enqueueSnackbar(t('Já existe uma conta para esse e-mail.'))
                    break;
                case 'auth/popup-closed-by-user':
                    break;
                default:
                    enqueueSnackbar(t(error.message), {variant: "error"});
            }
        } else {
            enqueueSnackbar(t(error.message), {variant: "error"});
        }
    }

    const _processSystemErrorOnSignIn = ({response}: AxiosError) => {

        if (!response) {
            enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
            return
        }

        const dataError = response.data as { statusCode: number, errorCode: string, timestamp: string }
        console.log(`An error happened.`)
        console.log(dataError)

        if (dataError.errorCode) {
            switch (dataError.errorCode) {
                case 'auth/user_deleted':
                    notifyDeletedAccount()
                    break
                case 'auth/user_suspended':
                    notifySuspendedAccount()
                    break
                default:
                    enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
            }
        } else {
            enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
        }
    }

    const notifySuspendedAccount = () => {
        const modal = showModal(SuspendedAccountModal, {
            onConfirm: () => {
                hideModal(modal.id)
                navigate('https://support.stashbunny.com')
            },
            onCancel: () => {
                hideModal(modal.id);
            }
        })
    }

    const notifyDeletedAccount = () => {
        const modal = showModal(DeletedAccountModal, {
            onConfirm: () => {
                hideModal(modal.id)
                navigate('/signup')
            },
            onCancel: () => {
                hideModal(modal.id)
            }
        })
    }

    const redirectToReferrer = () => {
        const state = location.state as { from };
        if (state && state.from) {
            const from = state.from?.pathname || "/";
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, {replace: true});
        }
    }

    return (
        <KonstaLoadingButton
            onClick={handleSign}
            loading={processing}
            rounded
            outline
            large
            className="theme-surface-inverse theme-on-surface-inverse normal-case border-[1px] !border-neutral-500 dark:border-none mb-6">
            <svg width="2443" height="2500" viewBox="0 0 256 262"
                 xmlns="http://www.w3.org/2000/svg"
                 preserveAspectRatio="xMidYMid"
                 className="w-5 h-5 group-disabled:hidden">
                <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"/>
                <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"/>
                <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"/>
                <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EB4335"/>
            </svg>
            &nbsp;&nbsp;&nbsp;
            {description}
        </KonstaLoadingButton>
    )
}

export default GoogleSignButton