import React, {useState} from "react";
import {ListItem} from "konsta/react";
import {useTranslation} from "react-i18next";
import {userService} from "../../services/UserService";
import useAuth from "../../hooks/useAuth";

export const DarkModeToggle = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') !== null && localStorage.getItem('theme') === 'dark')

    const toggleDarkMode = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const newDarkMode = !darkMode;

        if (newDarkMode) {
            localStorage.setItem('theme', newDarkMode ? 'dark' : 'light')
        } else {
            localStorage.removeItem('theme')
        }

        setDarkMode(newDarkMode)

        if (user) {
            userService.addOrUpdatePreference(user.id, 'theme', newDarkMode ? 'dark' : 'light')
        }

        const moon = document.querySelector('#moon');
        const sun = document.querySelector('#sun');

        moon?.classList.add('animate-spin');
        sun?.classList.add('animate-spin');

        setTimeout(() => {
            document.documentElement.classList.toggle('dark')

            if (newDarkMode) {
                document.querySelector('meta[name="theme-color"]')?.setAttribute('content', 'rgb(18 18 18)')
            } else {
                document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#fff')
            }

            moon?.classList.remove('animate-spin');
            sun?.classList.remove('animate-spin');
        }, 300);
    }

    return (
        <ListItem
            title={
                <div className="flex items-center gap-x-2">
                    <span>{darkMode ? t('light_theme') : t('dark_theme')}</span>
                    {!darkMode && <span className="bg-primary-500 text-white text-xs rounded px-1">{t('new')}</span>}
                </div>
            }
            media={
                <div
                    className="flex items-center justify-center w-5 h-5 rounded-full bg-gray-900 dark:bg-neutral-600 transform-none origin-center hover:cursor-pointer">
                    <div>
                        <div className="rotate-360 translate-z-0"
                            // style="transform: rotate(360deg) translateZ(0px);"
                        >
                            <svg id="moon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24"
                                 strokeLinecap="round" strokeLinejoin="round"
                                 className="block dark:hidden w-4.5 h-4.5 text-white transform-none"
                                 height="1em" width="1em"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M12 1.992a10 10 0 1 0 9.236 13.838c.341 -.82 -.476 -1.644 -1.298 -1.31a6.5 6.5 0 0 1 -6.864 -10.787l.077 -.08c.551 -.63 .113 -1.653 -.758 -1.653h-.266l-.068 -.006l-.06 -.002z"
                                    strokeWidth="0" fill="currentColor"></path>
                            </svg>

                            <svg id="sun" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24"
                                 strokeLinecap="round" strokeLinejoin="round"
                                 className="hidden dark:block w-4.5 h-4.5 text-yellow-500 transform-none"
                                 height="1em"
                                 width="1em"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M12 19a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M18.313 16.91l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 1.218 -1.567l.102 .07z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M7.007 16.993a1 1 0 0 1 .083 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7a1 1 0 0 1 1.414 0z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M4 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M21 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M6.213 4.81l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 1.217 -1.567l.102 .07z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M19.107 4.893a1 1 0 0 1 .083 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7a1 1 0 0 1 1.414 0z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M12 2a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z"
                                    strokeWidth="0" fill="currentColor"></path>
                                <path
                                    d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z"
                                    strokeWidth="0" fill="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            }
            onClick={toggleDarkMode}
        />
    )
}
