import api from '../apis';
import {DirectMessage} from '../models/DirectMessage';
import {ResponseData} from '../types/ResponseData';
import {MessageType} from '../types/Message';
import {GroupThreadDTO} from '../models/GroupThreadDTO';
import {CreateGroupThreadRequest} from '../requests/CreateGroupThreadRequest';
import {SendTypingRequest} from "../requests/SendTypingRequest";
import {FeedbackRequest} from "../models/FeedbackRequest";

class DirectService {
    getThreads = (pageParam: number, filter: string): Promise<ResponseData<GroupThreadDTO>> =>
        api
            .get(`direct/threads?page=${pageParam}&size=16&filter=${filter}`)
            .then((response) => response.data);

    getThread = (threadId: string): Promise<GroupThreadDTO> =>
        api
            .get(`direct/threads/${threadId}`)
            .then((response) => response.data);

    createGroupThread = (request: CreateGroupThreadRequest): Promise<GroupThreadDTO> =>
        api
            .post('direct/threads/create_group_thread', request)
            .then((response) => response.data)
            .catch((error) => {
                this.handleError(error);
            });

    sendMessage = (message: DirectMessage) =>
        api
            .post('direct/messages', message)
            .then((response) => response.data)
            .catch((error) => {
                this.handleError(error);
            });

    getMessages = (roomId: string, pageParam: number): Promise<ResponseData<MessageType.Any[]>> => {
        return api
            .get(`direct/${roomId}/messages?date=${pageParam}&size=30`)
            .then((response) => response.data)
            .catch((error) => {
                this.handleError(error);
            });
    }

    getSeen = (): Promise<any> =>
        api
            .get(`direct/seen`)
            .then((response) => response.data)

    myChats = (): Promise<ResponseData> => {
        return api
            .get(`direct/my-chats`)
            .then((response) => response.data)
            .catch((error) => {
                this.handleError(error);
            });
    };

    sendTyping = (request: SendTypingRequest) =>
        api
            .post('direct/send-typing', request)
            .then((response) => response.data);

    sendFeedback = (feedback: FeedbackRequest) =>
        api
            .post('direct/feedback', feedback)
            .then((response) => response.data);

    handleError(error: any) {
        console.log(error.message);
    }
}

export const directService = new DirectService();
