import React, {memo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-query";
import Fade from "@mui/material/Fade";
import {Controller, useForm} from "react-hook-form";
import {FeedbackRequest} from "../../models/FeedbackRequest";
import {directService} from "../../services/DirectService";
import {BlockFooter, BlockTitle, List, ListInput, ListItem, Navbar, Popup, Radio} from "konsta/react";
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";
import {classNames} from "../../utils/utils";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Button from "@mui/material/Button";

interface FeedbackDialogProps {
    from: string,
    opened?: boolean
    onCancel: () => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({from, onCancel, ...props}) => {
    const {t} = useTranslation();
    const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const {
        register, setValue, getValues, handleSubmit, control, watch, formState: {errors, isValid}
    } = useForm<FeedbackRequest>({
        mode: "onChange",
        defaultValues: {
            feedbackKey: ''
        }
    });

    const key = watch('feedbackKey');

    const handleFormSubmit = (event) => {
        event.preventDefault()
        handleSubmit(onSubmit)(event)
    }

    const onSubmit = data => saveMutation.mutate(data);

    const saveMutation = useMutation((request: FeedbackRequest) => directService.sendFeedback(request),
        {
            onMutate: () => {
                setIsProcessing(true)
            },
            onSuccess: () => {
                setFeedbackSent(true)
            },
            onError: () => {
                setIsProcessing(false)
            }
        })

    return (
        <>
            <div
                className={classNames(props.opened ? 'theme-backdrop' : 'opacity-0 pointer-events-none')}/>
            <Popup
                {...props}
                backdrop={false}
                className="theme-surface theme-on-surface sm:max-w-[400px]">
                <Navbar
                    title={t('give_feedback')}
                    transparent={false}
                    bgClassName="theme-surface hairline-b-none"
                    // innerClassName="!ml-2"
                    // left={
                    //     !feedbackSent ? <NavbarBackLink showText={false} onClick={onCancel}
                    //                                     className="theme-on-surface ml-2"/> : null
                    // }
                    left={
                        <IconButton onClick={onCancel} className="!mt-1 !-ml-1">
                            <CloseOutlinedIcon />
                        </IconButton>
                    }
                />

                <form className="max-h-full h-full overflow-y-auto pb-[104px]">

                    {feedbackSent ? (
                        <div className="px-4 flex flex-col items-center justify-center gap-y-8 h-full">
                            <Fade in={feedbackSent}>
                                <div className="flex flex-col justify-center items-center text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512" className="h-12 text-green-500 fill-current">
                                        <path
                                            d="M235.3 331.3C229.1 337.6 218.9 337.6 212.7 331.3L148.7 267.3C142.4 261.1 142.4 250.9 148.7 244.7C154.9 238.4 165.1 238.4 171.3 244.7L224 297.4L340.7 180.7C346.9 174.4 357.1 174.4 363.3 180.7C369.6 186.9 369.6 197.1 363.3 203.3L235.3 331.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"/>
                                    </svg>

                                    <h1 className="theme-on-surface text-lg font-bold mb-1 mt-4">{t('thanks_for_the_feedback')}</h1>
                                    <p className="theme-on-surface-bright">{t('your_feedback_is_important_to_help_us_improve_even_more')}</p>
                                </div>
                            </Fade>

                            <Button variant="soft" sx={{px: 4}} onClick={onCancel}>
                                {t('close')}
                            </Button>
                        </div>
                    ) : (
                        <>
                            {/*<BlockHeader className="mb-2">{t('share_your_thoughts')}</BlockHeader>*/}

                            <List inset={false} dividers={false}>

                                <BlockTitle>{t('i_want_to')}</BlockTitle>

                                <Controller
                                    control={control}
                                    {...register('feedbackKey', {
                                        required: true,
                                    })}
                                    name="feedbackKey"
                                    render={({field}) => (
                                        <>
                                            <List dividers={false}>
                                                {['ask_a_question', 'leave_a_comment', 'report_a_bug', 'suggest_an_improvement'].map(value => (
                                                    <ListItem
                                                        label
                                                        title={t(value)}
                                                        media={
                                                            <Radio
                                                                value={value}
                                                                checked={field.value === value}
                                                                component="div"
                                                                onClick={() => setValue('feedbackKey', value, {shouldValidate: true})}
                                                            />
                                                        }
                                                    />
                                                ))}
                                            </List>
                                            <BlockFooter colors={{textIos: 'text-red-500'}} className="text-xs">
                                                {errors.feedbackKey && errors.feedbackKey.type === 'required' ? (
                                                    t('Por favor, escolha um tipo de feedback.')
                                                ) : null}
                                            </BlockFooter>
                                        </>
                                    )}
                                />


                                <Controller
                                    control={control}
                                    {...register('description', {
                                        required: true,
                                    })}
                                    name="description"
                                    render={({field}) => (
                                        <ListInput
                                            outline
                                            label={t(key === 'ask_a_question' ? 'what_would_you_like_to_know'
                                                : key === 'leave_a_comment' ? 'let_us_know_whats_on_your_mind'
                                                    : key === 'report_a_bug' ? 'describe_the_bug_or_issue'
                                                        : key === 'suggest_an_improvement' ? 'let_us_know_what_youd_like_to_improve' : '')}
                                            type="textarea"
                                            inputClassName="resize-none textarea-h-28"
                                            placeholder={t('let_us_know_whats_on_your_mind')}
                                            input={<textarea {...field}
                                                             className="block text-base appearance-none w-full focus:outline-none bg-transparent resize-none textarea-h-28 h-10 placeholder-black placeholder-opacity-30 py-2"/>}
                                            error={
                                                errors.description && errors.description.type === 'required' ? (
                                                    t('Por favor, escreva uma pouco sobre como podemos ajudar.')
                                                ) : null
                                            }
                                        />
                                    )}
                                />
                            </List>
                            <div className="z-10 theme-surface left-0 right-0 fixed py-2 px-2 bottom-0-safe">
                                <KonstaLoadingButton rounded large loading={isProcessing}
                                    // disabled={!isValid}
                                                     onClick={handleFormSubmit}>
                                    {t('give_feedback')}
                                </KonstaLoadingButton>
                            </div>
                        </>
                    )}
                </form>
            </Popup>
        </>
    )
}

export default memo(FeedbackDialog);
