import { Outlet } from 'react-router-dom';
import NavbarVertical from './../main/navbar/NavbarVertical';
import useResponsive from '../../hooks/useResponsive';
import { classNames } from '../../utils/utils';
import React from 'react';

const FullLayout = () => {
    const isCollapsed = useResponsive('down', 'lg');
    const isMobile = useResponsive('down', 'sm');

    if (isMobile) {
        return <Outlet />;
    }

    return (
        <div className="fixed inset-0 overflow-y-auto w-full min-h-screen flex flex-grow justify-center theme-surface">

            <div className={classNames(!!isCollapsed ? 'w-16' : 'w-60', 'flex-shrink-0 relative')}>
                <div className="h-full relative">
                    <div className={classNames(!!isCollapsed ? 'w-16' : 'w-60', 'fixed top-0 w-16 h-full')}>
                        <NavbarVertical collapsed={!!isCollapsed} />
                    </div>
                </div>
            </div>

            <main className="min-w-0 w-[900px]">
                <Outlet />
            </main>

        </div>
    );
}

export default FullLayout;
