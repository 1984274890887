import React from 'react';
import {createRoot} from 'react-dom/client';

import './styles/light.css';
import './styles/dark.css';
import './index.css';
import './theme.css';
import './story.css';
import './styles/konstaui-custom.css';
import './styles/swiperjs-custom.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import './i18n';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import pt from 'javascript-time-ago/locale/pt.json'
import es from 'javascript-time-ago/locale/es.json'

import {BrowserRouter} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import {MaterialDesignContent, SnackbarProvider} from "notistack";
import {ModalProvider as MuiModalProvider} from "mui-modal-provider";
import {I18nProvider} from "@react-aria/i18n";
import {AuthProvider} from "./context/FirebaseContext";
import Router from "./routes";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ThemeProvider from "./theme";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {styled} from "@mui/material/styles";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import 'dayjs/locale/pt-br'
import {KonstaProvider} from "konsta/react";
import {ModalProvider} from "./shared/modal-provider";

Bugsnag.start({
    apiKey: '4b5d6575d284f5a5adf23bc7fd3a36c4',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production']
})

TimeAgo.addDefaultLocale(pt)
TimeAgo.addLocale(en)
TimeAgo.addLocale(es)

const queryClient = new QueryClient();

const StyledSnackbarContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent': {
        borderRadius: '1rem !important'
    }
}));

const container = document.getElementById('root')!;
const root = createRoot(container);

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

root.render(
    // <React.StrictMode>
    <ErrorBoundary>
        <Provider store={store}>
            <KonstaProvider theme="ios" dark>
                <ThemeProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <CookiesProvider>
                            <AuthProvider>
                                <I18nProvider locale="pt-BR">
                                    <QueryClientProvider client={queryClient}>
                                        <BrowserRouter>
                                            <ModalProvider>
                                                <MuiModalProvider>

                                                    <SnackbarProvider
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        preventDuplicate={true}
                                                        Components={{
                                                            default: StyledSnackbarContent,
                                                            success: StyledSnackbarContent,
                                                            info: StyledSnackbarContent,
                                                            error: StyledSnackbarContent,
                                                            warning: StyledSnackbarContent,
                                                        }}
                                                    >
                                                        {/*<App safeAreas theme="ios" dark>*/}
                                                        <Router/>
                                                        {/*</App>*/}
                                                    </SnackbarProvider>

                                                </MuiModalProvider>
                                            </ModalProvider>
                                        </BrowserRouter>
                                    </QueryClientProvider>
                                </I18nProvider>
                            </AuthProvider>
                        </CookiesProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </KonstaProvider>
        </Provider>
    </ErrorBoundary>
    // </React.StrictMode>
);

const notify = (registration: ServiceWorkerRegistration) => {
    registration.waiting?.postMessage({type: 'SKIP_WAITING'});
    // const requestSkipWaiting = ({id}: Toast) => {
    //     toast.dismiss(id);
    //     registration.waiting?.postMessage({type: 'SKIP_WAITING'});
    // }
    //
    // toast.custom(
    //     (t) => <UpdateAvailableToast onDismiss={() => toast.dismiss(t.id)}
    //                                  onUpdateClick={() => requestSkipWaiting(t)}/>,
    //     {
    //         position: isMobile ? 'bottom-center' : 'bottom-right',
    //         duration: Infinity
    //     }
    // );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({onUpdate: notify});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
