import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import 'react-phone-number-input/style.css';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {CREATOR_TOTAL_STEPS} from '../../pages/CreatorProfileConvertPage';
import CreatorProfileContext from '../../context/CreatorProfileConfigContext';
import {userService} from '../../services/UserService';
import {useQuery} from 'react-query';
import LoadingIndicator from '../shared/LoadingIndicator';
import {CreatorAccount} from '../../models/CreatorAccount';
import moment from 'moment/moment';
import PaginatorDot from '../shared/PaginatorDot';
import {useLocation, useNavigate} from 'react-router-dom';

import { cpf } from 'cpf-cnpj-validator';
import {useSnackbar} from "notistack";
import Bugsnag from "@bugsnag/js";

type FormData = {
    fullName: string;
    cpf: string;
    birthDate: string;
    phoneNumber: any;
};

type CreatorAccountPersonalDataProps = {
    dialogMode?: boolean
}

const CreatorAccountPersonalData = (props: CreatorAccountPersonalDataProps) => {
    const {isLoading, data} = useQuery(
        'creatorProfilePersonalInformation',
        () => userService.getCreatorAccount()
    );

    if (isLoading || !data) {
        return (
            <div className="w-full sm:max-w-lg h-96">
                <LoadingIndicator/>
            </div>
        );
    }

    return <Content profile={data} dialogMode={props.dialogMode}/>;
}

type Props = {
    profile: CreatorAccount
    dialogMode?: boolean
}

const Content = (props: Props) => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const {profile, dialogMode} = props;
    const { enqueueSnackbar } = useSnackbar();

    const {state, setState} = useContext(CreatorProfileContext);
    const [saving, setSaving] = useState(false);

    const {control, handleSubmit, register, formState} =
        useForm<FormData>({
            mode: 'onChange',
            defaultValues: {
                fullName: profile.identity?.fullName,
                cpf: profile.identity?.ni,
                birthDate: moment(profile.identity?.birthDate).format('DD/MM/YYYY'),
                phoneNumber: profile.identity?.cellPhone
            }
        });
    const {errors, isValid} = formState;

    const goToPrev = () => setState({...state, step: 'adult-content'});

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        if (saving) return;
        try {
            setSaving(true);

            profile.identity.fullName = formData.fullName;
            profile.identity.ni = formData.cpf;
            profile.identity.birthDate = moment(formData.birthDate, 'DD/MM/YYYY').toDate();
            profile.identity.cellPhone = formData.phoneNumber;
            await userService.saveCreatorAccount(profile);

            if (dialogMode) {
                navigate('/settings/account')
            } else {
                // setState({...state, step: 'bank-data', profile})
                navigate('/settings/identity_confirm/', {state: location.state, replace: true});
            }
        } catch (error: any) {
            Bugsnag.notify(error);
            enqueueSnackbar(t(`Error at save creator profile personal information: ${error.message}`));
            console.log(`Error at save creator profile personal information: ${error.message}`);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="py-6">
            <div className="w-full sm:max-w-lg mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    {!dialogMode && (<PaginatorDot className="mb-4" curPage={3} maxPage={CREATOR_TOTAL_STEPS}/>)}
                    <div className="text-3xl leading-8 font-light tracking-tight text-gray-900 sm:text-3xl">
                        {t('Informações pessoais')}
                    </div>
                    <div className="mt-2 sm:px-4 max-w-2xl font-light text-base text-gray-500 mx-auto">
                        {t(
                            'Informe seus dados pessoais, eles serão solicitados pelo banco para transferência dos seus ganhos.'
                        )}
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-6">
                        <p className="text-sm flex items-center text-gray-500">
                            {t(
                                'Essas informações são privadas e nunca serão exibidas no seu perfil.'
                            )}
                        </p>
                        <div className="pt-4 pb-2">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-12">
                                    <label
                                        htmlFor="fullName"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('Nome completo')}
                                    </label>
                                    <input
                                        {...register('fullName', {
                                            required: true,
                                            pattern: {
                                                value: /^([a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšž]{3,})+\s+([a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšž\s]{3,})+$/i,
                                                message:
                                                    'Digite o nome completo',
                                            },
                                        })}
                                        type="text"
                                        name="fullName"
                                        autoComplete="off"
                                        className="sm:text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
                                    />
                                    {errors.fullName &&
                                    errors.fullName?.type === 'pattern' && (
                                        <p className="mt-2 text-sm text-red-600">
                                            {errors.fullName.message}
                                        </p>
                                    )}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="cpf"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        CPF
                                    </label>
                                    <Controller
                                        control={control}
                                        {...register('cpf', {
                                            required: true,
                                            validate: {
                                                valid: (value) => cpf.isValid(value),
                                            },
                                        })}
                                        name="cpf"
                                        render={({field}) => (
                                            <div>
                                                <InputMask
                                                    {...field}
                                                    mask="999.999.999-99"
                                                    type="text"
                                                    className="sm:text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
                                                />
                                                {errors.cpf && errors.cpf?.type === 'valid' && (
                                                    <span className="text-xs text-red-600">
                                                        Invalid CPF.
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="birthDate"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('Data de nascimento')}
                                    </label>
                                    <Controller
                                        control={control}
                                        {...register('birthDate', {
                                            required: true,
                                            validate: {
                                                valid: (value) => validate_data(value),
                                            },
                                        })}
                                        name="birthDate"
                                        render={({field}) => (
                                            <>
                                                <InputMask
                                                    {...field}
                                                    mask="99/99/9999"
                                                    maskPlaceholder={null}
                                                    type="text"
                                                    className="sm:text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
                                                />
                                                {errors.birthDate &&
                                                errors.birthDate?.type ===
                                                'valid' && (
                                                    <p className="mt-2 text-sm text-red-600">
                                                        Data inválida.
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {!dialogMode && (
                        <div className="border-t mt-6 pt-6 flex items-center justify-between text-gray-900">
                            <button
                                onClick={goToPrev}
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            >
                                {t('back')}
                            </button>

                            <button
                                type="submit"
                                disabled={!isValid}
                                className="disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-primary hover:bg-primary-600"
                            >
                                {t('next')}
                            </button>
                        </div>
                    )}

                    {dialogMode && (
                        <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                            <button
                                type="submit"
                                disabled={!isValid}
                                className="disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                {t('done')}
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

function validate_data(value: string | null) {
    if (!value) {
        return false;
    }
    const values = value.split('/');
    if (values.length !== 3) {
        return false;
    }
    if (parseInt(values[0]) > 31 || parseInt(values[1]) > 12 || parseInt(values[2]) < 1000) {
        return false;
    }
    return true;
}

export default CreatorAccountPersonalData;
