import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CREATOR_TOTAL_STEPS} from '../../pages/CreatorProfileConvertPage';
import {userService} from '../../services/UserService';
import CreatorProfileContext from '../../context/CreatorProfileConfigContext';
import {RadioGroup} from '@headlessui/react'
import {classNames} from '../../utils/utils';
import PaginatorDot from '../shared/PaginatorDot';
import {useNavigate} from 'react-router-dom';
import {ArrowRightIcon} from "@heroicons/react/outline";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {UserContentPolicyRequest} from "../../requests/UserContentPolicyRequest";
import {useSnackbar} from "notistack";
import useBackRoute from "../../hooks/useBackRoute";
import Bugsnag from "@bugsnag/js";
import {Block, BlockTitle, Checkbox, List, ListItem, Navbar, NavbarBackLink, Radio} from "konsta/react";
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";

const options = [
    {name: 'Não, meu trabalho não contém nudez real ou ilustrada.', value: false},
    {name: 'Sim, meu trabalho contém nudez real ou ilustrada.', value: true},
]

type CreatorProfileAdultContentProps = {
    dialogMode?: boolean
    onDialogClose?: () => void
}

const CreatorProfileAdultContent = (props: CreatorProfileAdultContentProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {back} = useBackRoute();
    const { enqueueSnackbar } = useSnackbar();
    const {dialogMode, onDialogClose} = props;

    const {state, setState} = useContext(CreatorProfileContext);

    const [saving, setSaving] = useState(false);
    const [adultContent, setAdultContent] = useState(state.profile.adultContent);
    const [acceptedTerm, setAcceptedTerm] = useState(state.profile.adultContent);

    const goToNext = async () => {
        if (saving) return;
        try {
            setSaving(true);
            const request = {} as UserContentPolicyRequest;
            request.adultContent = adultContent && acceptedTerm;
            await userService.saveUserContentPolicy(request);

            if (dialogMode) {
                navigate('/settings/account')
                if (onDialogClose) {
                    onDialogClose();
                }
            } else {
                setState({...state, step: 'monetization'})
                // navigate('/settings/identity_confirm/', {state: location.state, replace: true});
            }
        } catch (error: any) {
            Bugsnag.notify(error);
            enqueueSnackbar(t(`Erro ao salvar as políticas de conteúdo: ${error.message}`));
            console.log(`Erro ao salvar as políticas de conteúdo: ${error.message}`);
        } finally {
            setSaving(false);
        }
    };

    const goToPrev = () => setState({...state, step: 'categories'});

    const onAdultContentChange = (value: boolean) => {
        setAdultContent(value);
        setAcceptedTerm(false);
    };

    const onTermAcceptanceChange = (
        event
    ) => {
        const checked = event.target.checked;
        setAcceptedTerm(checked);
    };

    return (
        <>
            <Navbar
                left={
                    <NavbarBackLink showText={false} onClick={goToPrev} className="theme-on-surface ml-2"/>
                }
                right={
                    <KonstaLoadingButton clear
                                         loading={saving}
                                         disabled={adultContent && !acceptedTerm}
                                         onClick={goToNext}>
                        {dialogMode ? t('save') : t('next')}
                    </KonstaLoadingButton>
                }
            />

            <div className="p-4">
                <div className="text-center">
                    {!dialogMode && (
                        <PaginatorDot className="mb-4" curPage={2} maxPage={CREATOR_TOTAL_STEPS}/>)}
                    <img
                        src="/images/18.png"
                        alt=""
                        className="w-24 h-24 mx-auto"
                    />
                    <div
                        className="mt-2 sm:px-6 text-3xl leading-8 font-medium tracking-tight">
                        {t(
                            'Seu trabalho contém conteúdo para maiores de 18 anos?'
                        )}
                    </div>
                </div>

                <Block>
                    <p className="text-justify">
                        {t(
                            'A Stash Bunny é um lugar onde todos os tipos de artistas podem se expressar livremente. Ao mesmo tempo, precisamos garantir que a experiência de uso da plataforma seja apropriada para diferentes faixas etárias, culturas e contextos. O material "para maior de 18 anos" significa que apresenta nudez real ou ilustrada, ou qualquer criação que possa ser considerada inadequada para menores de 18 anos.'
                        )}
                    </p>
                </Block>

                <BlockTitle className="theme-on-surface">{t('adult_content_desc')}</BlockTitle>
                <List>
                    {options.map((options, planIdx) => (
                        <ListItem
                            label
                            key={options.name}
                            title={options.name}
                            media={
                                <Radio
                                    component="div"
                                    value={options.value}
                                    checked={options.value === adultContent}
                                    onChange={() => onAdultContentChange(options.value)}
                                />
                            }
                        />
                    ))}
                </List>

                {adultContent && (
                    <List nested>
                        <ListItem
                            label
                            subtitle={
                                <div className="text-justify">
                                            <span
                                                className="font-medium"
                                            >
                                                Quando meu conteúdo mostrar imagens ou
                                                vídeos de pessoas reais
                                            </span>
                                    <span
                                        id="comments-description"
                                        className=""
                                    >
                                                , atesto que verifiquei a identidade dos
                                                participantes do conteúdo em questão,
                                                que todos são maiores de idade, que
                                                solicitei, recebi e armazenei o
                                                consentimento deles por escrito para
                                                participar e para que a imagem deles
                                                seja enviada, visualizada e baixada
                                                nesta plataforma, e concordo em cumprir
                                                os Termos de Uso, a Política de
                                                Privacidade e as Orientações para a
                                                Comunidade.
                                            </span>
                                </div>
                            }>

                        </ListItem>
                        <ListItem
                            label
                            title={t('Concordo com o termo acima.')}
                            media={
                                <Checkbox
                                    component="div"
                                    name="demo-checkbox"
                                    checked={acceptedTerm}
                                    onChange={(value) =>
                                        onTermAcceptanceChange(value)
                                    }
                                />
                            }
                        />
                    </List>
                )}
            </div>
        </>
    );
};

export default CreatorProfileAdultContent;
