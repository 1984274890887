import { matchPath } from 'react-router-dom';
import { FunctionComponent, SVGProps } from 'react';

export const getActive = (path, pathname) => path ? !!matchPath({path, end: true}, pathname) : false;

export interface Nav {
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
    activeIcon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
    title: string;
    path: string;
}
