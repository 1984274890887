import {createContext, useReducer} from 'react';

interface State {
    showOptions: boolean,
    email?: string
}

const initialState: State = {
    showOptions: true,
};

const reducer = (state, action) => {
    if (action.type === 'UPDATE_SHOW_OPTIONS') {
        const payload = action.payload;
        return {
            ...state,
            showOptions: payload,
        };
    }

    return state;
};

const LoginContext = createContext({
    ...initialState,
    showLoginForm: () => {
    }
});

// ----------------------------------------------------------------------

function LoginProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const showLoginForm = () => dispatch({type: 'UPDATE_SHOW_OPTIONS', payload: false});

    return (
        <LoginContext.Provider
            value={{
                ...state,
                showLoginForm
            }}
        >
            {children}
        </LoginContext.Provider>
    );
}

export {LoginContext, LoginProvider};
