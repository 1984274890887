import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CREATOR_TOTAL_STEPS} from '../../pages/CreatorProfileConvertPage';
import {userService} from '../../services/UserService';
import CreatorProfileContext from '../../context/CreatorProfileConfigContext';
import {useQuery} from 'react-query';
import LoadingIndicator from '../shared/LoadingIndicator';
import PaginatorDot from '../shared/PaginatorDot';
import {Category} from '../../models/Category';
import {UserCategoriesRequest} from "../../requests/UserCategoriesRequest";
import {useSnackbar} from "notistack";
import useBackRoute from "../../hooks/useBackRoute";
import Bugsnag from "@bugsnag/js";
import {BlockHeader, Checkbox, List, ListItem, Navbar, NavbarBackLink, Toggle} from "konsta/react";
import {KonstaLoadingButton} from "../../shared/components/KonstaLoadingButton";

type CreatorAccountCategoriesProps = {
    dialogMode?: boolean
}

const CreatorProfileCategories = ({dialogMode}: CreatorAccountCategoriesProps) => {
    const {t} = useTranslation();
    const {back} = useBackRoute();
    const { enqueueSnackbar } = useSnackbar();
    const {state, setState} = useContext(CreatorProfileContext);
    const [saving, setSaving] = useState(false);
    const [showCategoriesOnProfile, setShowCategoriesOnProfile] = useState(state.profile.showCategoriesOnProfile);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>(
        state.profile.categories || []
    );

    const {isLoading, data: categories} = useQuery(
        'categories',
        () => userService.getCategories()
    );

    const onCheckboxChange = (category: Category) => {
        const selected = selectedCategories;

        if (selected.some(item => item.id === category.id)) {
            const index = selected.findIndex(item => item.id === category.id);
            if (index > -1) {
                selected.splice(index, 1);
            }
        } else {
            selected.push(category);
        }

        setSelectedCategories([...selected]);
    };

    const handleShowOnProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowCategoriesOnProfile(event.target.checked);
    };

    const goToNext = async () => {
        if (saving) return;

        try {
            setSaving(true);

            const request = {} as UserCategoriesRequest;
            request.showCategoriesOnProfile = showCategoriesOnProfile;
            request.categories = selectedCategories;
            await userService.saveUserCategories(request);

            if (dialogMode) {
                back();
            } else {
                setState({...state, step: 'adult-content'})
            }
        } catch (error: any) {
            Bugsnag.notify(error);
            enqueueSnackbar(t(`Erro ao salvar as categorias: ${error.message}`));
            console.log(`Error at save categories: ${error.message}`);
        } finally {
            setSaving(false);
        }
    }

    const goToPrev = () => setState({...state, step: 'welcome-faq'});

    return (
        <>
            <Navbar
                left={
                    <NavbarBackLink showText={false} onClick={goToPrev} className="theme-on-surface ml-2"/>
                }
                right={
                    <KonstaLoadingButton clear
                                         loading={saving}
                                         disabled={!selectedCategories.length}
                                         onClick={goToNext}>
                        {dialogMode ? t('save') : t('next')}
                    </KonstaLoadingButton>
                }
            />

            <div className="p-4">
                <div className="text-center">
                    {!dialogMode && (<PaginatorDot className="mb-4" curPage={1} maxPage={CREATOR_TOTAL_STEPS}/>)}
                    <div className="text-3xl leading-8 font-medium tracking-tight">
                        {t('select_a_category')}
                    </div>
                    <div className="mt-2 sm:px-4 max-w-2xl text-base font-light">
                        {t('choose_the_category_that_best_describes_what_you_do')}
                    </div>
                </div>

                {(isLoading) ? (
                    <div className="py-28">
                        <LoadingIndicator/>
                    </div>
                ) : (
                    <>
                        <List className="!my-2.5">
                            <ListItem
                                label
                                title={t('show_category_in_profile')}
                                after={
                                    <Toggle
                                        component="div"
                                        className="-my-1"
                                        checked={showCategoriesOnProfile}
                                        onChange={handleShowOnProfileChange}
                                    />
                                }
                            />
                        </List>

                        <BlockHeader
                            className="">{t('categories_desc')} {t('you_can_choose_up_to_two_categories')}</BlockHeader>
                        <List>
                            {categories?.map((category, personIdx) => (
                                <ListItem
                                    key={category.id}
                                    label
                                    title={t(category.name)}
                                    media={
                                        <Checkbox
                                            component="div"
                                            name={`${category.id}-checkbox`}
                                            disabled={
                                                selectedCategories.length === 2 &&
                                                !selectedCategories.some(value => value.id === category.id)
                                            }
                                            checked={selectedCategories.some(value => value.id === category.id)}
                                            onChange={() => onCheckboxChange(category)}
                                        />
                                    }
                                />
                            ))}
                        </List>
                    </>
                )}
            </div>
        </>
    );
};

export default CreatorProfileCategories;
