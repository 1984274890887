import {Trans, useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {PATH_AUTH} from "../../../routes/paths";
import GoogleSignButton from "../GoogleSignButton";
import TwitterSignButton from "../TwitterSignButton";
import useSignup from "../../../hooks/useSignup";
import {Button} from "konsta/react";
import Logo from "../../Logo";

const SignupOptions = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const {hideOptions} = useSignup();

    return (
        <div className="theme-on-surface flex flex-col items-center justify-center py-4 px-6 sm:px-20 mx-auto max-w-[500px] ">

            <Logo className="h-12 mb-4"/>

            <h1 className="theme-on-surface text-3xl font-bold mb-10">
                <Trans t={t} i18nKey="join_stashbunny_today">
                    <span className="whitespace-nowrap">Stash Bunny</span>
                </Trans>
            </h1>

            <div className="flex-1"></div>

            <GoogleSignButton type="sign_up" description={t('sign_up_with_google')}/>

            <TwitterSignButton type="sign_up" description={t('sign_up_with_twitter')}/>

            <div className="flex w-full -mx-1 my-3">
                <div className="min-w-0 basis-0 flex items-center justify-center mx-1 flex-1">
                    <div className="my-2 w-full relative hairline-t hairline-neutral-700"></div>
                </div>
                <div
                    className="css-1rynq56 r-qvutc0 r-37j5jr r-1inkyih r-rjixqe r-16dba41 r-hvzyjp r-1iusvr4 r-bcqeeo r-4amgru"><span
                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"><span
                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3">{t('or')}</span></span>
                </div>
                <div className="min-w-0 basis-0 flex items-center justify-center mx-1 flex-1">
                    <div className="my-2 w-full relative hairline-t hairline-neutral-700"></div>
                </div>
            </div>

            <Button rounded
                    large
                    onClick={hideOptions}
                    className="normal-case">
                {t('create_account')}
            </Button>

            <div className="w-full text-xs text-gray-500 mt-4">
                <Trans t={t}
                       i18nKey="by_signing_up_you_agree_to_our_terms_of_service_and_privacy_policy">
                    <Link to="https://support.stashbunny.com/terms-of-service" target="_blank"
                          className="font-medium text-accent-600 hover:text-accent-700">{t('terms_of_service')}</Link>
                    <Link to="https://support.stashbunny.com/privacy-policy" target="_blank"
                          className="font-medium text-accent-600 hover:text-accent-700">{t('privacy_policy')}</Link>
                </Trans>
            </div>

            <div className="w-full text-lg mt-10 flex items-center gap-x-2">
                <span className="text-gray-900 dark:text-neutral-400">{t('already_have_an_account')}</span>
                <Link to={PATH_AUTH.login}
                      replace={true}
                      state={location.state}
                      className="text-primary hover:text-primary-600">
                    {t('sign_in')}
                </Link>
            </div>
        </div>
    )
}

export default SignupOptions;
