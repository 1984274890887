import {useLocation, useNavigate} from 'react-router-dom';
import {getActive, Nav} from './index';
import {useTranslation} from 'react-i18next';
import {PATH_AUTH} from "../../routes/paths";
import useAuth from "../../hooks/useAuth";
import React from "react";
import {ListItem} from "konsta/react";

interface Props {
    collapsed: boolean;
    item: Nav;
}

const NavItem = ({collapsed, item}: Props) => {
    const {title, path} = item;
    const {t} = useTranslation();
    const {user} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = useLocation();
    const active = getActive(item.path, pathname)

    const goToPath = () => {
        if (path === '/' && !user) {
            navigate(PATH_AUTH.register, {state: {from: '/', backgroundLocation: location}})
            return;
        }

        navigate(path);
    }

    return (
        <ListItem dividers={false}
                  title={!collapsed && t(title)}
                  media={active ? <item.activeIcon className="theme-on-surface h-6 w-6"/> :
                      <item.icon className="theme-on-surface h-6 w-6"/>}
                  onClick={goToPath}
        />
    )

    // return (
    //     <ListItemButton className="rounded-full justify-center" onClick={goToPath}>
    //         <ListItemIcon sx={{ minWidth: collapsed ? 'unset' : '46px' }}>
    //             {active ? <item.activeIcon className="text-gray-900 h-7 w-7" /> : <item.icon className="text-gray-900 h-7 w-7" />}
    //         </ListItemIcon>
    //         {!collapsed && (
    //             <ListItemText primary={t(title)}
    //                           primaryTypographyProps={active ? { fontWeight: 'bold', fontSize: '1.25rem', lineHeight: '1.75rem' } : { fontSize: '1.25rem', lineHeight: '1.75rem' }}/>
    //         )}
    //     </ListItemButton>
    // )
}

export default NavItem;
