import {useLocation, useNavigate} from 'react-router-dom';
import {getActive, Nav} from './index';
import {useTranslation} from 'react-i18next';
import {useQuery} from "react-query";
import {directService} from "../../services/DirectService";
import useAuth from "../../hooks/useAuth";
import Badge from "@mui/material/Badge";
import React from "react";
import {ListItem} from "konsta/react";

interface Props {
    collapsed: boolean;
    item: Nav;
}

const NavMessagesItem = ({collapsed, item}: Props) => {
    const {title, path} = item;
    const {user} = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const active = getActive(item.path, pathname);

    const {data} = useQuery(
        'get_seen', () => directService.getSeen(),
        {
            enabled: !!user
        }
    )

    return (
        <ListItem dividers={false}
                  title={!collapsed && t(title)}
                  media={
                      <Badge badgeContent={data?.unseenCount} color="error">
                          {active ? <item.activeIcon className="theme-on-surface h-6 w-6"/> :
                              <item.icon className="theme-on-surface h-6 w-6"/>}
                      </Badge>
                  }
                  onClick={() => navigate(path)}
        />
    )

    // return (
    //     <ListItemButton className="rounded-full justify-center" onClick={() => navigate(path)}>
    //         <ListItemIcon sx={{minWidth: collapsed ? 'unset' : '46px'}}>
    //             <Badge badgeContent={data?.unseenCount} color="error">
    //                 {active ? <item.activeIcon className="text-gray-900 h-7 w-7"/> :
    //                     <item.icon className="text-gray-900 h-7 w-7"/>}
    //             </Badge>
    //         </ListItemIcon>
    //         {!collapsed && (
    //             <ListItemText primary={t(title)}
    //                           primaryTypographyProps={active ? {
    //                               fontWeight: 'bold',
    //                               fontSize: '1.25rem',
    //                               lineHeight: '1.75rem'
    //                           } : {fontSize: '1.25rem', lineHeight: '1.75rem'}}/>
    //         )}
    //     </ListItemButton>
    // )
}

export default NavMessagesItem;
