import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import useAuth from '../../../hooks/useAuth';
import {PATH_MAIN} from '../../../routes/paths';
import {useSnackbar} from 'notistack';
import Bugsnag from "@bugsnag/js";
import BadgeCheckIcon from "../../../components/shared/icons/BadgeCheckIcon";
import {List, ListItem} from "konsta/react";
import {ConfirmDialog} from "../../../components/shared/components/ConfirmDialog";
import {useModal} from "../../../shared/modal-provider";
import {PopoverOpener} from "../../../components/PopoverOpener";

interface Props {
    collapsed: boolean;
}

const NavbarProfileMenu = ({collapsed}: Props) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const {user, logout} = useAuth();
    const {enqueueSnackbar} = useSnackbar();
    const {showModal, hideModal} = useModal();

    const openPopover = (targetRef) => {
        const modal = showModal(PopoverOpener, {
            target: targetRef,
            children: (
                <List nested>
                    <ListItem
                        title={t('view_profile')}
                        media={
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512" className="h-5 w-5 theme-on-surface"
                                 fill="currentColor">
                                <path
                                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-47.24 0-91.04-14.78-127.2-39.84C132.9 390.9 173.8 352 224 352h64c50.25 0 91.14 38.94 95.21 88.16C347 465.2 303.2 480 256 480zM411.7 416.7C397.6 361.3 347.7 320 288 320H224c-59.73 0-109.6 41.3-123.7 96.72C58.27 375.1 32 319 32 256c0-123.5 100.5-224 224-224s224 100.5 224 224C480 319 453.7 375.1 411.7 416.7zM256 128C211.8 128 176 163.8 176 208C176 252.2 211.8 288 256 288s80-35.82 80-80C336 163.8 300.2 128 256 128zM256 256C229.5 256 208 234.5 208 208S229.5 160 256 160s48 21.53 48 48S282.5 256 256 256z"/>
                            </svg>
                        }
                        onClick={() => navigate(`/${user?.username}`)}
                    />

                    <ListItem
                        title={t('logout')}
                        media={
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512" className="h-5 w-5 theme-on-surface"
                                 fill="currentColor">
                                <path
                                    d="M507.3 244.7l-144-144c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L457.4 240H176C167.2 240 160 247.2 160 256s7.156 16 16 16h281.4l-116.7 116.7c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0l144-144C510.4 264.2 512 260.1 512 256S510.4 247.8 507.3 244.7zM176 448h-96C53.53 448 32 426.5 32 400v-288C32 85.53 53.53 64 80 64h96C184.8 64 192 56.84 192 48S184.8 32 176 32h-96C35.88 32 0 67.88 0 112v288C0 444.1 35.88 480 80 480h96C184.8 480 192 472.8 192 464S184.8 448 176 448z"/>
                            </svg>
                        }
                        onClick={logOut}
                    />
                </List>
            ),
            onClose: () => {
                hideModal(modal.id)
            }
        })
    }

    const handleLogout = async () => {
        try {
            await logout();
            navigate(PATH_MAIN.root, {replace: true});
        } catch (error: any) {
            console.error(error);
            Bugsnag.notify(error);
            enqueueSnackbar('Unable to logout!', {variant: 'error'});
        }
    };

    const logOut = () => {
        const modal = showModal(ConfirmDialog, {
            title: t('log_out_of_account', {username: user?.username}),
            contentText: '',
            confirmText: t('log_out'),
            onConfirm: () => {
                hideModal(modal.id);
                handleLogout();
            },
            onCancel: () => {
                hideModal(modal.id);
            }
        });
    }

    return (
        <>
            <div className="group cursor-pointer block w-full flex-shrink-0" id="navbar-profile-menu"
                 onClick={() => openPopover(`#navbar-profile-menu`)}>
                {collapsed ? (
                    <div className="flex items-center justify-center mx-auto py-4">
                        <Avatar src={user?.profilePicUrl || ''}
                                alt={user?.displayName || ''}
                                sx={{width: 32, height: 32}}
                                className="theme-bg-avatar"/>
                    </div>
                ) : (
                    <div
                        className="flex items-center w-full p-4 hover:bg-gray-50 dark:hover:bg-neutral-800 hover:rounded-l-2xl">
                        <div>
                            <Avatar src={user?.profilePicUrl || ''}
                                    alt={user?.displayName || ''}
                                    sx={{width: 40, height: 40}}
                                    className="theme-bg-avatar"/>
                        </div>
                        <div className="ml-3">
                            <div
                                className="text-base font-medium theme-on-surface overflow-ellipsis overflow-hidden whitespace-nowrap flex items-center gap-x-1.5">
                                {user?.displayName}
                                {user?.verified ? (
                                    <BadgeCheckIcon
                                        className="h-3.5 text-primary leading-8 hover:scale-150 transition ease-in-out duration-500"/>
                                ) : null}
                            </div>
                            <p className="text-sm font-medium text-gray-600 dark:text-neutral-500 group-hover:text-gray-700">{`@${user?.username}`}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default NavbarProfileMenu;
