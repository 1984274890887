import {createContext, useEffect, useReducer, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {
    getAuth,
    signOut,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    TwitterAuthProvider,
    browserPopupRedirectResolver,
} from 'firebase/auth';
//
import {FIREBASE_API} from '../config';
import {userService} from "../services/UserService";
import {UserSummary} from "../models/UserSummary";
import {auth} from "../firebase";
import {authService} from "../services/AuthService";
import Bugsnag from "@bugsnag/js";

// ----------------------------------------------------------------------

interface State {
    isAuthenticated: boolean,
    isInitialized: boolean,
    user: UserSummary | null
}

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const reducer = (state, action) => {
    if (action.type === 'INITIALISE') {
        const {isAuthenticated, user} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    } else if (action.type === 'UPDATE_USER') {
        const {user} = action.payload;
        return {
            ...state,
            user,
        };
    }

    return state;
};

const AuthContext = createContext({
    ...initialState,
    method: 'firebase',
    login: (email: string, password: string) => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signInWithTwitter: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    updateUser: (user: UserSummary) => {
    }
});

// ----------------------------------------------------------------------

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const [profile, setProfile] = useState<UserSummary | null>(null);

    useEffect(
        () =>
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    try {
                        const userSummary = await userService.getUserSummary(user.uid);

                        // configFirebaseUser(userSummary);

                        if (userSummary) {
                            setProfile(userSummary);
                        }

                        dispatch({
                            type: 'INITIALISE',
                            payload: {isAuthenticated: true, user},
                        });
                    } catch (e: any) {
                        if (!e.response || e.response.status !== 401) {
                            Bugsnag.notify(e);
                        }
                        dispatch({
                            type: 'INITIALISE',
                            payload: {isAuthenticated: false, user: null},
                        });
                    }
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {isAuthenticated: false, user: null},
                    });
                }
            }),
        [dispatch]
    );

    const login = (email, password) => signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
        const user = userCredential.user;

        if (user) {
            await authService.completeLogin(user);
        }
    });

    const signInWithGoogle = () => signInWithPopup(auth, new GoogleAuthProvider())
        .then(async (userCredential) => {
            const user = userCredential.user;

            if (user) {
                await authService.completeLogin(user);
            }
        })
        .catch((error) => authService._processErrorOnSignIn(error));

    const signInWithTwitter = () => signInWithPopup(auth, new TwitterAuthProvider())
            .then(async (userCredential) => {
                const user = userCredential.user;

                if (user) {
                    await authService.completeLogin(user);
                }
            })
            .catch((error) => authService._processErrorOnSignIn(error));

    const register = (email, password, firstName, lastName) =>
        createUserWithEmailAndPassword(auth, email, password).then(async (res) => {

        });

    const logout = async () => {
        dispatch({
            type: 'INITIALISE',
            payload: {isAuthenticated: false, user: null},
        });
        await signOut(auth);
    }

    const updateUser = (user: UserSummary) => dispatch({type: 'UPDATE_USER', payload: {user}});

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'firebase',
                user: profile,
                login,
                signInWithGoogle,
                signInWithTwitter,
                register,
                logout,
                updateUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export {AuthContext, AuthProvider};
