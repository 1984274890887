import {Trans, useTranslation} from "react-i18next";

import {ReactComponent as EyeIcon} from '../../../assets/icons/eye.svg';
import React, {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import {authService} from "../../../services/AuthService";
import Bugsnag from "@bugsnag/js";
import {PATH_AUTH} from "../../../routes/paths";
import {createUserWithEmailAndPassword, updateProfile} from "firebase/auth";
import {auth} from "../../../firebase";
import {FirebaseError} from "@firebase/util";
import {AxiosError} from "axios";
import SuspendedAccountModal from "../../shared/components/dialog/SuspendedAccountModal";
import DeletedAccountModal from "../DeletedAccountModal";
import {useModal} from "../../../shared/modal-provider";
import {List, ListInput} from "konsta/react";

import '../styles/signup.css'
import {KonstaLoadingButton} from "../../../shared/components/KonstaLoadingButton";
import Logo from "../../Logo";

type FormData = {
    name: string;
    email: string;
    password: string;
}

const SignupEmail = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const [processing, setProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const { showModal, hideModal } = useModal()

    const {control, setError, handleSubmit, register, formState} =
        useForm<FormData>({
            mode: 'onChange',
        });
    const {errors, isValid} = formState;

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        handleSubmit(onSubmit)(event)
    }

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        if (processing) return;
        try {
            setProcessing(true)

            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password)

            if (userCredential.user) {
                await updateProfile(userCredential.user, {displayName: formData.name})
                await authService.completeLogin(userCredential.user)
            }

            redirectToReferrer()
        } catch (error: unknown) {
            if (error instanceof FirebaseError) {
                _processAuthErrorOnSignIn(error)
            } else {
                _processSystemErrorOnSignIn(error as any)
            }
        } finally {
            setProcessing(false);
        }
    }

    const _processAuthErrorOnSignIn = (error: FirebaseError) => {
        Bugsnag.notify(error);
        console.log(`An error happened. ${error}`);

        if (error.code) {
            switch(error.code) {
                case 'auth/email-already-in-use':
                    setError('email', {type: 'auth/email-already-in-use'});
                    break;
                case 'auth/weak-password':
                    setError('password', {type: 'auth/weak-password'});
                    break;
                case 'auth/user-not-found':
                    setError('email', {type: 'auth/user-not-found'});
                    break;
                case 'auth/wrong-password':
                    setError('password', {type: 'auth/wrong-password'});
                    break;
                case 'auth/popup-closed-by-user':
                    break;
                default:
                    enqueueSnackbar(t(error.message), {variant: "error"});
            }
        } else {
            enqueueSnackbar(t(error.message), {variant: "error"});
        }
    }

    const _processSystemErrorOnSignIn = ({response}: AxiosError) => {

        if (!response) {
            enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
            return
        }

        const dataError = response.data as { statusCode: number, errorCode: string, timestamp: string }
        console.log(`An error happened.`)
        console.log(dataError)

        if (dataError.errorCode) {
            switch (dataError.errorCode) {
                case 'auth/user_deleted':
                    notifyDeletedAccount()
                    break
                case 'auth/user_suspended':
                    notifySuspendedAccount()
                    break
                default:
                    enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
            }
        } else {
            enqueueSnackbar('Ocorreu um problema. Caso persista contate nosso suporte.')
        }
    }

    const notifySuspendedAccount = () => {
        const modal = showModal(SuspendedAccountModal, {
            onConfirm: () => {
                hideModal(modal.id)
                navigate('https://support.stashbunny.com')
            },
            onCancel: () => {
                hideModal(modal.id);
            }
        })
    }

    const notifyDeletedAccount = () => {
        const modal = showModal(DeletedAccountModal, {
            onConfirm: () => {
                hideModal(modal.id)
                navigate('/signup')
            },
            onCancel: () => {
                hideModal(modal.id)
            }
        })
    }

    const redirectToReferrer = () => {
        const state = location.state as { from };
        if (state && state.from) {
            const from = state.from?.pathname || "/";
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, {replace: true});
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}
              className="theme-on-surface overflow-y-auto flex flex-col items-center justify-center py-4 px-6 sm:px-20 mx-auto max-w-[500px]">

            <Logo className="h-12 mb-4"/>

            <h1 className="w-full theme-on-surface text-3xl font-bold mb-6">
                {t('create_a_free_account')}
            </h1>

            <List inset={false} dividers={false} className="list-input-mx-0 !my-0 w-full">
                <Controller
                    control={control}
                    {...register('name', {
                        required: true
                    })}
                    render={({field: {...restField}}) => (
                        <ListInput
                            outline
                            label={t('name')}
                            type="text"
                            input={<input {...restField}
                                          autoFocus={true}
                                          autoComplete="off"
                                          className="block text-base appearance-none w-full focus:outline-none bg-transparent  h-10 placeholder-black placeholder-opacity-30"/>}
                            error={
                                errors.name && errors.name.type === 'required' ? (
                                    <span>{t('whats_your_name')}</span>
                                ) : null
                            }
                        />
                    )}
                />

                <Controller
                    control={control}
                    {...register('email', {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                    })}
                    render={({field: {...restField}}) => (
                        <ListInput
                            outline
                            label={t('email')}
                            type="email"
                            autoComplete="off"
                            input={<input {...restField}
                                          className="block text-base appearance-none w-full focus:outline-none bg-transparent  h-10 placeholder-black placeholder-opacity-30"/>}
                            error={
                                errors.email && errors.email.type === 'pattern' ? (
                                    t('please_enter_a_valid_email')
                                ) : errors.email && errors.email.type === 'required' ? (
                                    t('enter_your_best_email')
                                ) : errors.email && errors.email.type === 'auth/email-already-in-use' ? (
                                    <Trans i18nKey="email_already_in_use">
                                        <Link to={PATH_AUTH.login}
                                              replace={true}
                                              state={location.state}
                                              className="hover:underline font-semibold">
                                            {t('sign_in')}
                                        </Link>
                                    </Trans>
                                ) : null
                            }
                        />
                    )}
                />

                <Controller
                    control={control}
                    {...register('password', {
                        required: true,
                    })}
                    render={({field: {...restField}}) => (
                        <ListInput
                            outline
                            label={t('password')}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="off"
                            input={
                                <div className="flex items-center">
                                    <input {...restField}
                                           type={showPassword ? 'text' : 'password'}
                                           className="block text-base appearance-none w-full focus:outline-none bg-transparent  h-10 placeholder-black placeholder-opacity-30"/>
                                    <button
                                        className="flex items-center justify-end h-[24px] w-[24px]"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <EyeIcon className="w-4 text-gray-900 opacity-50"/> :
                                            (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                     fill="currentColor"
                                                     stroke="currentColor" className="w-5 text-gray-900">
                                                    <path
                                                        d="M2.8 7.8c2.1 1 4.5 1.6 7 1.6s4.9-.6 7-1.6m-7 2v3M5.1 9.2l-1.5 2.6m11-2.6 1.5 2.6"
                                                        opacity=".5"></path>
                                                </svg>
                                            )}
                                    </button>
                                </div>
                            }
                            error={
                                errors.password && errors.password.type === 'required' ? (
                                    t('enter_a_good_password')
                                ) : errors.password && errors.password.type === 'auth/wrong-password' ? (
                                    t('oops_wrong_password')
                                ) : errors.password && errors.password.type === 'auth/weak-password' ? (
                                    t('weak_password')
                                ) : null
                            }
                        />
                    )}
                />
            </List>

            <div className="w-full text-xs text-gray-500 mt-2 mb-6">
                <Trans t={t}
                       i18nKey="by_signing_up_you_agree_to_our_terms_of_service_and_privacy_policy">
                    <Link to="https://support.stashbunny.com/terms-of-service" target="_blank"
                          className="font-medium text-accent-600 hover:text-accent-700">{t('terms_of_service')}</Link>
                    <Link to="https://support.stashbunny.com/privacy-policy" target="_blank"
                          className="font-medium text-accent-600 hover:text-accent-700">{t('privacy_policy')}</Link>
                </Trans>
            </div>

            <KonstaLoadingButton rounded
                                 large
                                 disabled={!isValid}
                                 loading={processing}
                                 onClick={handleFormSubmit}
                                 className="">
                {t('sign_up')}
            </KonstaLoadingButton>
        </form>
    )
}

export default SignupEmail;
