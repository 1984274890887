import {useContext} from 'react';
import {SignupContext} from "../context/SignupContext";

const useSignup = () => {
    const context = useContext(SignupContext);

    if (!context) throw new Error('Auth context must be use inside AuthProvider');

    return context;
};

export default useSignup;
