import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ChatState {
    amount: number;
    message?: string;
}

const initialState: ChatState = {
    amount: 10,
}

export const tipSlice = createSlice({
    name: 'tip',
    initialState,
    reducers: {
        clearState: state => state = initialState,
        changeAmount: (state, action: PayloadAction<number>) => {
            state.amount = action.payload;
        },
        changeMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        }
    }
})

export const {clearState, changeAmount, changeMessage} = tipSlice.actions

export default tipSlice.reducer